import React, { useContext } from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Stack,
  Image,
} from '@chakra-ui/react';
import { AppContext } from '../context/AppContext';
import theme from '../theme';
import image from 'src/assets/images/image1.png';
const AboutUs = () => {
  const context = useContext(AppContext);

  if (!context) {
    return null;
  }

  const { aboutUsContent } = context;

  return (
    <Box
      mx={['16px', '32px', '64px', '120px']}
      mt={['24px', '48px', '68px']}
      fontFamily={theme.fonts.family.body}
      fontWeight="400"
      fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
      color="#0C0B0B"
    >
      <VStack
        spacing={8}
        align="stretch"
        textAlign="right"
        px={['20px', '40px', '50px']}
        borderRadius="16px"
      >
        {aboutUsContent.sections.map((section, index) => (
          <Stack
            key={index}
            direction={['column', 'row']}
            spacing="53px"
            align="center"
          >
            <Image
              width={['500px', '550px', '600px']}
              height="100%"
              src={image}
              alt={`Section image ${index + 1}`}
              loading="lazy"
              mr={['0px', '-60px']}
            />
            <VStack spacing={4} align={['center', 'start', 'start']}>
              <Heading
                fontFamily={theme.fonts.family.bold}
                fontWeight="500"
                fontSize={`clamp(48px, 1.5vw, 60px)`}
                color={theme.colors.secondary}
                mb={5}
              >
                {aboutUsContent.heading}
              </Heading>
              {section.texts.map((text, i) => (
                <Text key={i} fontSize="lg" textAlign="start">
                  {text}
                </Text>
              ))}
            </VStack>
          </Stack>
        ))}
        <HStack p={['20px', '30px', '50px']} align="center">
          <Text
            fontFamily={theme.fonts.family.bold}
            fontWeight="500"
            fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
            textAlign="center"
          >
            {aboutUsContent.finalText}
          </Text>
        </HStack>
      </VStack>
    </Box>
  );
};

export default AboutUs;
