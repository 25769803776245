import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import theme from 'src/theme';
import { ReactComponent as ArrowRightIcon } from 'src/assets/mobile/icons/ArrowRightIcon.svg';

interface PagesHeaderProps {
  text: string;
  showBackButton?: boolean;
  bgc?: string;
}

const PagesHeader: React.FC<PagesHeaderProps> = ({
  text,
  showBackButton = false,
  bgc = '#ffff',
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleBackClick = () => {
    // console.log('router.asPath: ', router.asPath);
    if (location.pathname === '/new-request') {
      navigate('/dashboard');
    } else {
      navigate(-1);
    }
  };

  return (
    <Flex
      as="header"
      alignItems="center"
      justifyContent="center"
      height="56px"
      minHeight="56px"
      mb="18px"
      width="100%"
      backgroundColor={bgc}
      position="fixed"
      top="0"
      left="50%"
      transform="translateX(-50%)"
      zIndex="1000"
      boxShadow={isScrolled ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none'}
      transition="box-shadow 0.5s ease-in-out"
      maxWidth="600px"
    >
      <Text
        fontFamily={theme.fonts.family.medium}
        fontSize={theme.fonts.size.small}
        fontWeight="400"
        lineHeight="20px"
        textAlign="center"
        flex="1"
      >
        {text}
      </Text>
      {showBackButton && (
        <Box
          onClick={handleBackClick}
          position="absolute"
          right="16px"
          top="50%"
          transform="translateY(-50%)"
          p="20px"
          cursor="pointer"
          _hover={{
            bg: 'rgba(0, 0, 0, 0.05)',
          }}
        >
          <ArrowRightIcon />
        </Box>
      )}
    </Flex>
  );
};

export default PagesHeader;
