import axios, { AxiosError } from 'axios';
import { API_BASE_URL } from './constants';

interface VoucherValidationResponse {
  time: string;
  code: number;
  message: string;
  body: {
    maxCeilingAmount: boolean;
    remainingUsage: number;
    discountAmount: number;
    expired: boolean;
    used: boolean;
  };
}

export const validateVoucher = async (
  code: string,
  userIdentifier: string,
  totalAmount: number
): Promise<number> => {
  try {
    const response = await axios.post<VoucherValidationResponse>(
      `${API_BASE_URL}/voucher/validate`,
      {
        code,
        userIdentifier,
        totalAmount,
      }
    );

    const { body } = response.data;

    if (
      !body.maxCeilingAmount &&
      !body.expired &&
      !body.used &&
      body.remainingUsage > 0
    ) {
      return body.discountAmount;
    } else {
      throw new Error('Voucher is not valid');
    }
  } catch (error) {
    console.error('Error validating voucher:', error);
    throw error;
  }
};
