import React from 'react';
import {
  Box,
  Heading,
  Text,
  Flex,
  Accordion,
  Image,
  Link,
} from '@chakra-ui/react';
import MoreContentIcon from '../assets/icons/MoreContentIcon.svg';
import Devider2 from '../assets/images/Devider2.svg';
import CustomAccordion from '../components/faq/Accordion';
import theme from '../theme';

const Faq: React.FC = () => {
  const faqs = [
    {
      question: ' ApplyTory چیست؟',
      answer:
        'اپلای‌طوری پلتفرم آنلاین است که به دانشجویان کمک می‌کند تا با اساتید دانشگاه‌های مختلف در سراسر جهان بر اساس رشته تحصیلی و رزومه خود ارتباط برقرار کنند. هدف اصلی این پلتفرم تسهیل فرآیند اپلای برای دریافت پذیرش تحصیلی و فاند است.',
      icon: MoreContentIcon,
    },
    {
      question: 'چگونه می‌توانم در ApplyTory ثبت نام کنم؟',
      answer:
        'برای ثبت نام در ApplyTory، می‌توانید از ایمیل یا حساب گوگل خود استفاده کنید. پس از ورود، یک کد تأیید به ایمیل شما ارسال می‌شود که باید آن را وارد کنید تا به راحتی ثبت نام شما کامل شود',
      icon: MoreContentIcon,
    },
    {
      question: 'چه نوع رزومه‌ای باید آپلود کنم؟',
      answer:
        'رزومه‌ای که آپلود می‌کنید باید به زبان انگلیسی و در قالب PDF باشد. رزومه بهتر است شامل اطلاعات شخصی، تحصیلات، تجربیات کاری، مهارت‌ها، انتشارات و مقالات، و جوایز و افتخارات باشد.',
      icon: MoreContentIcon,
    },
    {
      question: 'چرا باید علایق پژوهشی خود را انتخاب کنم؟',
      answer:
        'انتخاب دقیق علایق پژوهشی به ApplyTory کمک می‌کند تا بهترین تطابق‌ها را بین شما و اساتید در لیست نهایی به شما ارائه شود. با وارد کردن علایق خود به صورت دقیق، ما می‌توانیم اساتیدی را پیدا کنیم که در زمینه‌های مورد علاقه شما فعالیت می‌کنند، که این موضوع شانس شما برای دریافت پاسخ مثبت را افزایش می‌دهد.',
      icon: MoreContentIcon,
    },
    {
      question: 'چگونه می‌توانم علایق پژوهشی خود را انتخاب کنم؟',
      answer:
        'با وارد کردن کلمات کلیدی رایج و مرتبط با رشته تحصیلی خود و انتخاب آن ها از لیست میتوانید علایق خود را انتخاب کنید',
      icon: MoreContentIcon,
    },
    {
      question:
        'آیا می‌توانم قبل از ارتقا به نسخه PRO، لیست اساتید را مشاهده کنم؟',
      answer:
        'بله، در نسخه رایگان ApplyTory می‌توانید به لیست محدودی از اساتید به صورت رایگان دسترسی داشته باشید. برای دسترسی به لیست کامل و امکانات بیشتر، می‌توانید به نسخه PRO ارتقا دهید.',
      icon: MoreContentIcon,
    },
    {
      question: 'چگونه می‌توانم با تیم پشتیبانی تماس بگیرم؟',
      answer:
        'برای سوالات بیشتر یا دریافت راهنمایی، می‌توانید از طریق ایمیل یا شبکه‌های اجتماعی با تیم پشتیبانی ما با شماره ……………در تماس باشید. اطلاعات تماس ما در بخش "تماس با ما" وب‌سایت موجود است.',
      icon: MoreContentIcon,
    },
    {
      question: 'چگونه لیست اساتید به‌روزرسانی می‌شود؟',
      answer:
        'بانک اطلاعاتی اساتید ما روزانه به‌روزرسانی می‌شود تا مطمئن شویم که جدیدترین اطلاعات در اختیار کاربران قرار می‌گیرد.',
      icon: MoreContentIcon,
    },
    {
      question: 'چقدر طول می‌کشد تا لیست اساتید را دریافت کنم؟',
      answer:
        'در سریع‌ترین زمان ممکن، لیست اساتید در کمتر از 24 ساعت برای شما آماده و ارسال می‌شود.',
      icon: MoreContentIcon,
    },
    {
      question: 'آیا لیست اساتید شخصی‌سازی شده است؟',
      answer:
        'بله، لیست اساتید بر اساس علایق پژوهشی و سوابق موجود در رزومه شما به صورت شخصی‌سازی شده و منحصر به فرد برای شما تهیه می‌شود.',
      icon: MoreContentIcon,
    },
    {
      question: 'آیا به تاریخچه جستجوهای قبلی دسترسی دارم؟',
      answer:
        'بله، شما می‌توانید از طریق اکانت کاربری خود به تاریخچه جستجوهای قبلی‌تان دسترسی داشته باشید.',
      icon: MoreContentIcon,
    },
  ];

  return (
    <Box
      mx={{ base: '20px', md: '60px', lg: '120px', xl: '180px' }}
      mt={{ base: '24px', md: '48px', lg: '68px' }}
    >
      <Heading
        fontFamily={theme.fonts.family.medium}
        fontWeight="500"
        fontSize={{
          base: theme.fonts.size.medium,
          md: `clamp(${theme.fonts.size.big}, 1.5vw, ${theme.fonts.size.ultra})`,
        }}
        mb={5}
        textAlign="right"
        bg="#3080E233"
        p={{ base: '20px', md: '30px', lg: '40px' }}
        borderRadius="16px"
      >
        سوالات متداول
      </Heading>
      <Accordion allowToggle allowMultiple>
        {faqs.map((faq, index) => (
          <CustomAccordion
            key={index}
            id={index}
            question={faq.question}
            answer={faq.answer}
            icon={faq.icon}
          />
        ))}
      </Accordion>
      <Image
        src={Devider2}
        width="100%"
        my={{ base: '44px', md: '68px', lg: '88px' }}
      />
      <Heading
        fontFamily={theme.fonts.family.bold}
        fontWeight="700"
        fontSize={{
          base: theme.fonts.size.large,
          md: `clamp(${theme.fonts.size.ultra}, 1.5vw, ${theme.fonts.size.semiultra})`,
        }}
        mb="48px"
        textAlign="center"
      >
        روش‌های ارتباط با ما
      </Heading>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        mb={{ base: '34px', md: '48px', lg: '68px' }}
      >
        <Box
          ml={{ base: '0', md: '30px', lg: '60px', xl: '110px' }}
          textAlign="right"
          justifyContent="space-between"
          height="100%"
        >
          <Box mb={{ base: '24px', md: '40px', lg: '70px' }}>
            <Text
              fontFamily={theme.fonts.family.body}
              fontWeight="600"
              fontSize={{
                base: theme.fonts.size.small,
                md: `clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`,
              }}
              mb="6px"
            >
              آدرس:
            </Text>
            <Text
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={{
                base: theme.fonts.size.lil,
                md: `clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`,
              }}
            >
              بزرگراه سرلشکر بابایی، بلوار نیروی زمینی ارتش، مجتمع الماس، مرکز
              نوآوری فردا، طبقه اول
            </Text>
          </Box>
          <Box alignSelf="flex-end">
            <Flex
              direction={{ base: 'column', md: 'row' }}
              justifyContent="space-between"
              mb="24px"
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={{
                base: theme.fonts.size.small,
                md: `clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`,
              }}
              color="#0C0B0B"
            >
              <Text
                fontFamily={theme.fonts.family.body}
                fontWeight="600"
                fontSize={{
                  base: theme.fonts.size.small,
                  md: `clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`,
                }}
              >
                ارتباط سازمانی:
              </Text>
              <Text
                fontFamily={theme.fonts.family.body}
                fontWeight="500"
                fontSize={{
                  base: theme.fonts.size.lil,
                  md: `clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`,
                }}
              >
                info@ApplyTory.com
              </Text>
            </Flex>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              justifyContent="space-between"
              mb="24px"
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={{
                base: theme.fonts.size.small,
                md: `clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`,
              }}
              color="#0C0B0B"
            >
              <Text
                fontFamily={theme.fonts.family.body}
                fontWeight="600"
                fontSize={{
                  base: theme.fonts.size.small,
                  md: `clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`,
                }}
              >
                پشتیبانی:
              </Text>
              <Text
                fontFamily={theme.fonts.family.body}
                fontWeight="500"
                fontSize={{
                  base: theme.fonts.size.lil,
                  md: `clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`,
                }}
              >
                support@ApplyTory.com
              </Text>
            </Flex>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              justifyContent="space-between"
              mb="24px"
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={{
                base: theme.fonts.size.small,
                md: `clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`,
              }}
              color="#0C0B0B"
            >
              <Text
                fontFamily={theme.fonts.family.body}
                fontWeight="600"
                fontSize={{
                  base: theme.fonts.size.small,
                  md: `clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`,
                }}
              >
                شماره تماس:
              </Text>
              <Link href="tel:021-23456789">
                <Text
                  fontFamily={theme.fonts.family.body}
                  fontWeight="500"
                  fontSize={{
                    base: theme.fonts.size.lil,
                    md: `clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`,
                  }}
                >
                  02112345678
                </Text>
              </Link>
            </Flex>
          </Box>
        </Box>
        <Box textAlign="center" width="100%" mt={{ base: '24px', md: '0' }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.836753056975!2d51.34760161519899!3d35.76826188016856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f8e015d2c7ab1f9%3A0xfb9e0f6615468613!2sTehran!5e0!3m2!1sen!2sir!4v1638812418283!5m2!1sen!2sir"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen={false}
            loading="lazy"
            title="location"
          ></iframe>
        </Box>
      </Flex>
    </Box>
  );
};

export default Faq;
