import React, { useState, useMemo } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Input,
  VStack,
  HStack,
  useToast, // Importing toast functionality from Chakra UI
} from '@chakra-ui/react';

import theme from 'src/theme';

import Loader from 'src/components/Loader';
import PagesHeader from 'src/components/mobile/PagesHeader';

import { ReactComponent as TrashIcon } from 'src/assets/icons/trash.svg';
import { ReactComponent as AddCourseGpa } from 'src/assets/mobile/icons/AddCourseGpa.svg';

import { calculateGpa } from 'src/services/gpaService';
import GpaDegreeModal from 'src/components/mobile/GpaDegreeModal';
import GpaResultModal from 'src/components/mobile/GpaResultModal';

interface Record {
  courseid: number;
  courseunit: string;
  coursegrade: string;
  coursename: string;
}

const GpaCalculatorPage: React.FC = () => {
  const [records, setRecords] = useState<Record[]>([
    { courseid: 0, courseunit: '', coursegrade: '', coursename: '' },
    { courseid: 1, courseunit: '', coursegrade: '', coursename: '' },
    { courseid: 2, courseunit: '', coursegrade: '', coursename: '' },
  ]);
  const [loading, setLoading] = useState(false);
  const [gpaResult, setGpaResult] = useState<number | null>(null);
  const [isDegreeModalOpen, setIsDegreeModalOpen] = useState(false);
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const toast = useToast({
    position: 'top',
    containerStyle: {
      textAlign: 'center',
      width: '500px',
      maxWidth: '100%',
    },
  });
  // Check if all required fields in records are filled
  const allFieldsFilled = useMemo(() => {
    return records.every(
      (record) => record.courseunit.trim() && record.coursegrade.trim()
    );
  }, [records]);

  // Handle adding a new record
  const handleAddRecord = () => {
    const newRecordId = records.length;
    const updatedRecords = records.map((record) => {
      if (!record.coursename.trim()) {
        return { ...record, coursename: `درس ${record.courseid}` };
      }
      return record;
    });

    setRecords([
      ...updatedRecords,
      {
        courseid: newRecordId,
        courseunit: '',
        coursegrade: '',
        coursename: '',
      },
    ]);
  };

  // Handle removing a record
  const handleRemoveRecord = (courseid: number) => {
    setRecords((prevRecords) =>
      prevRecords.filter((record) => record.courseid !== courseid)
    );
  };

  // Handle input change and validation for grades
  const handleInputChange = (
    courseid: number,
    field: keyof Record,
    value: string
  ) => {
    setRecords((prevRecords) =>
      prevRecords.map((record) =>
        record.courseid === courseid ? { ...record, [field]: value } : record
      )
    );
  };

  // Validation for grades (between 0 and 20)
  const validateGrades = (): boolean => {
    const invalidRecord = records.some(
      (record) =>
        Number(record.coursegrade) < 0 || Number(record.coursegrade) > 20
    );
    if (invalidRecord) {
      toast({
        title: 'ورود ناقص اطلاعات',
        description: 'لطفا مقادیر ورودی را مجدد بررسی کنید',
        status: 'error',
        duration: 3000,
        containerStyle: {
          textAlign: 'center',
          fontFamily: `${theme.fonts.family.body}`,
          fontWeight: 'medium',
          backgroundColor: 'black',
          color: 'white',
        },
        isClosable: true,
      });
      return false;
    }
    return true;
  };

  // Modal functions
  const openDegreeModal = () => {
    if (validateGrades()) {
      setIsDegreeModalOpen(true);
    }
  };
  const closeDegreeModal = () => setIsDegreeModalOpen(false);

  const openResultModal = () => setIsResultModalOpen(true);
  const closeResultModal = () => {
    setIsResultModalOpen(false);
    closeDegreeModal(); // Optionally close the degree modal if needed
  };

  // GPA Calculation function
  const calculateUserGpa = async (degree: string) => {
    setLoading(true);
    closeDegreeModal(); // Close the degree modal immediately when calculation starts

    const requestBody = {
      educationLevel: degree === 'ارشد' ? 'Arshad' : 'Karshenasi',
      courseNames: records.map((r) => r.coursename || '-').join(','),
      creditHours: records.map((r) => r.courseunit).join(','),
      grades: records.map((r) => r.coursegrade).join(','),
    };

    try {
      const response = await calculateGpa(requestBody);
      setGpaResult(response.body.gpa);
      setLoading(false);
      openResultModal(); // Open the result modal to display the GPA
    } catch (error) {
      console.error('GPA calculation failed:', error);
      setLoading(false);
    }
  };

  return (
    <Flex
      position="relative"
      flexDirection="column"
      zIndex="1"
      backgroundColor={theme.colors.mobile.pbg}
      minHeight="100vh"
      paddingTop="56px"
      paddingBottom="80px"
    >
      {loading && <Loader color1="#56A2FF" color2="#FFF" size={100} />}
      <PagesHeader
        text={'محاسبه GPA'}
        showBackButton
        bgc={theme.colors.mobile.pbg}
      />

      {loading && <Loader color1="#56A2FF" color2="#FFF" size={100} />}
      <Box
        width="100%"
        textAlign="start"
        mb="20px"
        position="relative"
        zIndex="1"
        padding="16px"
      >
        <Text
          color="#57575A"
          fontFamily={theme.fonts.family.medium}
          fontSize={theme.fonts.size.small}
          fontWeight="500"
          marginBottom="24px"
        >
          برای جستجوی لیست اساتید اطلاعات زیر را وارد نمایید
        </Text>
        <VStack spacing={4} p={4}>
          <Box
            backgroundColor={theme.colors.mobile.mobileblue}
            width="100%"
            overflow="hidden"
            p="6px"
            borderRadius="8px"
          >
            <HStack
              justifyContent="space-between" // Adjust space between items
              spacing={0}
              paddingX="10px"
              width="100%"
            >
              <Text color={theme.colors.white} flex="1" justifyContent="center">
                عمل
              </Text>
              <Text color={theme.colors.white} flex="1" justifyContent="center">
                وزن
              </Text>
              <Text color={theme.colors.white} flex="1" justifyContent="center">
                نمره
              </Text>
              <Text color={theme.colors.white} flex="2" justifyContent="center">
                نام واحد درسی
              </Text>
            </HStack>
          </Box>
          <Box width="100%" overflow="hidden">
            {records.map((record) => (
              <HStack
                key={record.courseid}
                mb={2}
                backgroundColor={theme.colors.white}
                paddingLeft="10px"
                borderRadius="8px"
                py="4px"
              >
                <Box
                  onClick={() => handleRemoveRecord(record.courseid)}
                  cursor="pointer"
                  marginRight="15px"
                  marginLeft="5px"
                >
                  <TrashIcon />
                </Box>
                <Input
                  flex="2"
                  height="40px"
                  minHeight="40px"
                  placeholder="تعداد واحد"
                  value={record.courseunit}
                  onChange={(e) =>
                    handleInputChange(
                      record.courseid,
                      'courseunit',
                      e.target.value
                    )
                  }
                />
                <Input
                  flex="1"
                  placeholder="نمره"
                  value={record.coursegrade}
                  onChange={(e) =>
                    handleInputChange(
                      record.courseid,
                      'coursegrade',
                      e.target.value
                    )
                  }
                  borderColor={
                    Number(record.coursegrade) < 0 ||
                    Number(record.coursegrade) > 20
                      ? 'red.500'
                      : 'gray.300'
                  } // Apply red border for invalid grades
                />
                <Input
                  flex="3"
                  placeholder="نام واحد درسی"
                  value={record.coursename}
                  onChange={(e) =>
                    handleInputChange(
                      record.courseid,
                      'coursename',
                      e.target.value
                    )
                  }
                />
              </HStack>
            ))}
            <Flex
              onClick={handleAddRecord}
              alignItems="center"
              cursor="pointer"
            >
              <AddCourseGpa />
              <Text
                color="#57575A"
                fontFamily={theme.fonts.family.medium}
                fontSize={theme.fonts.size.small}
                fontWeight="500"
                marginTop="2px"
                marginRight="2px"
              >
                اضافه کردن رکورد جدید
              </Text>
            </Flex>
          </Box>
        </VStack>
      </Box>

      <Flex
        position="fixed"
        bottom="0"
        width="100%"
        backgroundColor="#FFFFFF"
        padding="16px"
        maxWidth="600px"
        transform="translateX(-50%)"
        left="50%"
      >
        <Button
          width="100%"
          borderRadius="8px"
          h="48px"
          backgroundColor={allFieldsFilled ? theme.colors.secondary : '#EBEBF0'}
          onClick={allFieldsFilled ? openDegreeModal : undefined}
          color={
            allFieldsFilled ? theme.colors.texts.white : theme.colors.secondary
          }
          _hover={{
            backgroundColor: !allFieldsFilled ? '#EBEBF0' : theme.colors.hover,
          }}
          cursor={allFieldsFilled ? 'pointer' : 'auto'}
          disabled={allFieldsFilled}
        >
          ثبت درخواست
        </Button>
      </Flex>
      <GpaDegreeModal
        isOpen={isDegreeModalOpen}
        onClose={closeDegreeModal}
        onConfirm={calculateUserGpa}
      />

      {gpaResult !== null && (
        <GpaResultModal
          isOpen={isResultModalOpen}
          onClose={closeResultModal}
          gpa={gpaResult}
        />
      )}
    </Flex>
  );
};

export default GpaCalculatorPage;
