import React, { useContext, useEffect, useState } from 'react';
import { Box, VStack, Text, Flex, useToast } from '@chakra-ui/react';
import moment from 'jalali-moment';
import 'moment/locale/fa';

import theme from 'src/theme';
import { AuthContext } from 'src/context/AuthContext';
import axios from 'axios';
import Loader from 'src/components/Loader';
import { handleUnauthorizedError } from 'src/services/authService';
import { useNavigate } from 'react-router-dom';
import PagesHeader from 'src/components/mobile/PagesHeader';
import { getAllTransactions } from 'src/services/paymentService';

import { ReactComponent as FailedTransaction } from 'src/assets/mobile/icons/FailedTransaction.svg';
import { ReactComponent as SuccsessTransaction } from 'src/assets/mobile/icons/SuccsessTransaction.svg';
import { ReactComponent as ClcokICon } from 'src/assets/mobile/icons/ClcokICon.svg';

interface Transaction {
  clientTrackingNumber: string;
  referenceNumber: string | null;
  reportTypeId: number;
  voucherCode: string | null;
  amount: number;
  discount: number | null;
  createdDate: string;
  success: boolean;
}

const UserTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext) || {};

  const fetchTransactions = async () => {
    try {
      const response = await getAllTransactions();
      setTransactions(response);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      handleUnauthorizedError(error);
      toast({
        title: 'Transaction error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };
  function formatPersianDate(dateString) {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat('fa-IR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    });

    // Extract parts and rearrange
    const parts = formatter.formatToParts(date);
    const dayName = parts.find((part) => part.type === 'weekday')?.value;
    const dayNum = parts.find((part) => part.type === 'day')?.value;
    const month = parts.find((part) => part.type === 'month')?.value;
    const year = parts.find((part) => part.type === 'year')?.value;

    return `${dayName}، ${dayNum} ${month} ${year}`;
  }
  function formatTime(dateString) {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat('fa-IR', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    return formatter.format(date);
  }

  useEffect(() => {
    fetchTransactions();
  }, []);

  return (
    <Box
      backgroundColor={theme.colors.mobile.pbg}
      minHeight="100vh"
      paddingTop="80px"
      paddingBottom="80px"
    >
      <Box backgroundColor="#fff">
        <PagesHeader text="تاریخچه پرداخت‌ها" showBackButton={true} />
      </Box>

      <VStack paddingX="16px" spacing="16px">
        {loading ? (
          <Loader color1="#56A2FF" color2="#FFF" size={200} />
        ) : (
          <>
            {transactions.map((transaction: Transaction, index) => (
              <Box
                key={transaction.clientTrackingNumber}
                padding="16px 12px 8px 12px"
                borderWidth={1}
                borderRadius="6px"
                width="100%"
                textAlign="center"
                backgroundColor="#fff"
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="start"
                  marginBottom="8px"
                >
                  <Flex>
                    {transaction.success ? (
                      <SuccsessTransaction />
                    ) : (
                      <FailedTransaction />
                    )}

                    <Text
                      fontFamily={theme.fonts.family.body}
                      fontSize={theme.fonts.size.small}
                      fontWeight="400"
                      marginRight="8px"
                    >
                      خرید
                      {transaction.success ? ' ' : ' ناموفق '}
                      {transaction.reportTypeId === 1
                        ? 'اشتراک رایگان'
                        : 'اشتراک بتا'}
                    </Text>
                  </Flex>
                  <Text
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.small}
                  >
                    <Text as="span" fontWeight="500" marginLeft="3px">
                      {transaction.amount.toLocaleString('fa-IR')}
                    </Text>
                    ریال
                  </Text>
                </Flex>
                <Flex alignItems="start">
                  <Text
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.small}
                    fontWeight="400"
                    marginLeft="8px"
                  >
                    {formatPersianDate(transaction.createdDate)}
                  </Text>
                  <Flex
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.small}
                    fontWeight="500"
                  >
                    <ClcokICon />
                    <Text
                      fontFamily={theme.fonts.family.body}
                      fontSize={theme.fonts.size.small}
                      fontWeight="400"
                      marginRight="4px"
                    >
                      {formatTime(transaction.createdDate)}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            ))}
          </>
        )}
      </VStack>
    </Box>
  );
};

export default UserTransactions;
