import React, { useContext } from 'react';
import { ChakraProvider, Box, Button, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { ReactComponent as BlueLogo } from 'src/assets/mobile/images/BlueLogo.svg';
import theme from 'src/theme';

const Splash: React.FC = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  if (!authContext) {
    return null;
  }

  const { isAuthenticated } = authContext;

  const handleButtonClick = () => {
    if (isAuthenticated) {
      navigate('/');
    } else {
      navigate('/login');
    }
    // Reload the page
    window.location.reload();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      minHeight="100vh"
      padding="16px"
      paddingBottom="80px"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flex="1"
      >
        <BlueLogo />
      </Box>
      <Flex width="100%" position="fixed" bottom="0" left="0" padding="16px">
        <Button
          borderRadius="8px"
          backgroundColor={theme.colors.texts.secondary}
          fontFamily={theme.fonts.family.bold}
          fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
          color={theme.colors.texts.white}
          padding="14px 36.5px"
          width="100%"
          _hover={{
            backgroundColor: theme.colors.hover,
          }}
          onClick={handleButtonClick}
        >
          شروع
        </Button>
      </Flex>
    </Box>
  );
};

export default Splash;
