import React, { useContext } from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Stack,
  Image,
} from '@chakra-ui/react';
import { AppContext } from '../context/AppContext';
import theme from '../theme';
import PagesHeader from 'src/components/mobile/PagesHeader';

const AboutUs = () => {
  const context = useContext(AppContext);

  if (!context) {
    return null;
  }

  const { aboutUsContent } = context;

  return (
    <Box
      minH="100vh"
      backgroundColor={theme.colors.mobile.pbg}
      paddingTop="56px"
      paddingBottom="80px"
    >
      <PagesHeader text={`درباره ما`} showBackButton={true} />
      <Box marginX="16px">
        <Box
          wordBreak="break-word"
          whiteSpace="normal"
          width="100%"
          textAlign="start"
          backgroundColor="white"
          fontFamily={theme.fonts.family.body}
          fontWeight="500"
          fontSize={theme.fonts.size.small}
          color={theme.colors.secondary}
          padding="16px"
          borderRadius="8px"
          marginTop="24px"
          marginBottom="16px"
        >
          <Text marginBottom="8px" fontWeight="700">
            داستان ما
          </Text>
          {aboutUsContent.sections.map((section, index) => (
            <Box>
              {section.texts.map((text, i) => (
                <Text textAlign="justify">{text}</Text>
              ))}
            </Box>
          ))}
          <Text marginTop="10px" textAlign="justify">
            {aboutUsContent.finalText}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUs;
