import { Container } from '@chakra-ui/react';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import BottomNavigation from 'src/components/mobile/BottomNavigation';
import { AuthContext } from 'src/context/AuthContext';

export const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    console.error('AuthContext is undefined in PrivateRoute');
    return null;
  }

  const { isAuthenticated, isLoading } = authContext;
  const noNavRoutes = [
    '/plans',
    '/contactus',
    '/moredetails',
    '/welcome',
    '/404',
    '/login',
    '/new-request',
    '/plans',
    '/payment',
    '/profile/transactions',
    '/aboutus',
    '/purchase-plans',
    '/gpa-calculator',
  ];
  const shouldShowNav = !noNavRoutes.some((route) =>
    location.pathname.match(route)
  );
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? (
    <Container maxW="600px" px="0" boxShadow={'0 2px 4px rgba(0, 0, 0, 0.1)'}>
      {children}
      {shouldShowNav && <BottomNavigation />}
    </Container>
  ) : (
    <Navigate to="/login" />
  );
};
