import React, { useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGoogleAuthCallback } from '../services/GoogleAuth';
import { AuthContext } from '../context/AuthContext';
import Loader from 'src/components/Loader';

const GoogleAuthCallback: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const processAuthCallback = async () => {
      const urlParams = new URLSearchParams(location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');
      console.log('URL Params:', urlParams);

      if (code && state) {
        try {
          await handleGoogleAuthCallback(urlParams.toString());
          if (authContext) {
            authContext.setIsAuthenticated(true);
          }
          navigate('/dashboard');
        } catch (error) {
          console.error('Error handling Google auth callback:', error);
          // Handle error, maybe show a notification or redirect to an error page
        }
      } else {
        // Handle the case where code or state is missing
        console.error('Missing code or state in callback');
      }
    };

    processAuthCallback();
  }, [location.search, navigate, authContext]);

  return (
    <div>
      <Loader color1="#56A2FF" color2="#FFF" size={200} />
    </div>
  );
};

export default GoogleAuthCallback;
