import React, { useState } from 'react';
import styles from './FloatingLabelInput.module.css'; // Import the module CSS

interface FloatingLabelInputProps {
  id: string;
  name: string;
  label: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  isValid: boolean;
}

const FloatingLabelInput: React.FC<FloatingLabelInputProps> = ({
  id,
  name,
  label,
  value = '',
  onChange,
  handleKeyDown,
  isValid,
}) => {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={styles.inputContain}>
      <input
        type="text"
        id={id}
        name={name}
        value={inputValue}
        onChange={handleChange}
        autoComplete="off"
        aria-labelledby={`placeholder-${id}`}
        className={styles.inputField} // Use className from module CSS
        onKeyDown={handleKeyDown}
        style={{ borderColor: isValid ? '' : '#BD272D' }}
      />
      <label
        className={styles.placeholderText}
        htmlFor={id}
        id={`placeholder-${id}`}
      >
        <div className={styles.text}>{label}</div>
      </label>
    </div>
  );
};

export default FloatingLabelInput;
