import React from 'react';
import { ChakraProvider, Spinner, Box } from '@chakra-ui/react';
import theme from './theme';
import { AppProvider } from './context/AppContext';
import { AuthProvider } from './context/AuthContext';
import useIsMobile from './hooks/useIsMobile';
import MobileLayout from './layouts/MobileLayout';
import DesktopLayout from './layouts/DesktopLayout';
import { BrowserRouter as Router } from 'react-router-dom';
import { PlanProvider } from './context/PlanContext';
import Loader from './components/Loader';

const App: React.FC = () => {
  const { isMobile, isLoading } = useIsMobile();

  if (isLoading) {
    return (
      <ChakraProvider theme={theme}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Loader color1="#56A2FF" color2="#404040" size={100} />
        </Box>
      </ChakraProvider>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <AppProvider>
          <PlanProvider>
            <Router>{isMobile ? <MobileLayout /> : <DesktopLayout />}</Router>
          </PlanProvider>
        </AppProvider>
      </AuthProvider>
    </ChakraProvider>
  );
};

export default App;
