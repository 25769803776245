import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
import theme from '../../theme';
import image_1 from '../../assets/images/image_content_1.png';
import image_2 from '../../assets/images/image_content_2.png';

const ImageContent: React.FC = () => {
  const imageSize = useBreakpointValue({ base: '300px', md: '480px' });

  return (
    <Flex
      w="100%"
      maxW="1169px"
      mx="auto"
      justify="center"
      align="center"
      mb={{ base: '40px', md: '88px' }}
      flexDirection={{ base: 'column', md: 'row' }}
    >
      <Flex
        w="100%"
        maxW="1169px"
        mx="auto"
        overflow="hidden"
        direction={{ base: 'column', md: 'row' }}
        h={{ base: 'auto', md: '550px' }}
      >
        <Box
          h={{ base: '400px', md: '550px' }}
          w={{ base: '90%', md: '50%' }}
          mx={{ base: 'auto', md: '0px' }}
          mb={{ base: '30px', md: '0px' }}
          ml={{ base: '20px', md: '70px' }}
          position="relative"
        >
          <Image
            src={image_2}
            alt="Example"
            objectFit="cover"
            w={imageSize}
            h={imageSize}
            position="absolute"
            top="0"
            left="0"
          />
          <Image
            src={image_1}
            alt="Example"
            objectFit="cover"
            w={imageSize}
            h={imageSize}
            position="absolute"
            bottom="0"
            right="0"
          />
        </Box>
        <Box
          w={{ base: '100%', md: '50%' }}
          textAlign={{ base: 'center', md: 'right' }}
        >
          <Heading
            fontFamily={theme.fonts.family.bold}
            fontWeight="700"
            fontSize={{
              base: '24px',
              md: `clamp(${theme.fonts.size.ultra}, 1.5vw, ${theme.fonts.size.semiultra})`,
            }}
            lineHeight="44px"
            color={theme.colors.texts.dark}
            mb="32px"
          >
            جستجوی آسان
          </Heading>
          <Text
            fontFamily={theme.fonts.family.body}
            fontWeight="400"
            fontSize={{
              base: '16px',
              md: `clamp(${theme.fonts.size.medium}, 1.5vw, ${theme.fonts.size.semimedium})`,
            }}
            color={theme.colors.texts.dark}
            lineHeight="24px"
            mb="40px"
          >
            برای شروع کار، زمینه مورد علاقه خود، کشور مقصد و رتبه خود را وارد
            کنید و در کمترین زمان لیست اساتید و دانشگاه‌ها را به صورت آنلاین
            دریافت نمایید.
          </Text>
          <Heading
            fontFamily={theme.fonts.family.bold}
            fontWeight="700"
            lineHeight="44px"
            fontSize={{
              base: '24px',
              md: `clamp(${theme.fonts.size.ultra}, 1.5vw, ${theme.fonts.size.semiultra})`,
            }}
            color={theme.colors.texts.dark}
            mb="32px"
          >
            انطباق علایق با لیست اساتید
          </Heading>
          <Text
            fontFamily={theme.fonts.family.body}
            fontWeight="400"
            fontSize={{
              base: '16px',
              md: `clamp(${theme.fonts.size.medium}, 1.5vw, ${theme.fonts.size.semimedium})`,
            }}
            color={theme.colors.texts.dark}
            lineHeight="24px"
            mb="40px"
          >
            اساتید مرتبط بر اساس علایق، تجارب کاری و مهارت های شما به شما نمایش
            داده می‌شوند تا شما بهترین مسیر برای شما افزایش پیدا کند.
          </Text>
          <Heading
            fontFamily={theme.fonts.family.bold}
            fontWeight="700"
            lineHeight="44px"
            fontSize={{
              base: '24px',
              md: `clamp(${theme.fonts.size.ultra}, 1.5vw, ${theme.fonts.size.semiultra})`,
            }}
            color={theme.colors.texts.dark}
            mb="32px"
          >
            صرفه‌جویی در زمان
          </Heading>
          <Text
            fontFamily={theme.fonts.family.body}
            fontWeight="400"
            fontSize={{
              base: '16px',
              md: `clamp(${theme.fonts.size.medium}, 1.5vw, ${theme.fonts.size.semimedium})`,
            }}
            color={theme.colors.texts.dark}
            lineHeight="24px"
          >
            دیگر نیازی به جستجوی دستی و یا اطلاعات مبهم زیاد جهت دریافت لیست
            اساتید نیستید و می‌توانید لیست مناسب برای رتبه خود را در یک ساعت
            دریافت نمایید.
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

export default ImageContent;
