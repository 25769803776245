import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Loader from 'src/components/Loader';
import FailedReport from 'src/components/mobile/FailedReport';
import PendingReport from 'src/components/mobile/PendingReport';
import ReportData from 'src/components/mobile/ReportData';
import { getReportWithId } from 'src/services/reportService';

interface Professor {
  id: number;
  author: string;
  scholarId: string | null;
  urlPicture: string;
  affiliation: string;
  email: string | null;
  interests: string[];
  authorCitations: number;
  university: string | null;
  country: string;
  feedBack: string;
  universityWorldRank: number;
  universityCountryRank: number;
  interestSimilarityScore: number;
}

interface ReportResponse {
  applyResultList: Professor[];
}

const Report: React.FC = () => {
  const location = useLocation();
  const [report, setReport] = useState<any>(null);
  const { reportId } = useParams<{ reportId: string }>();
  const index = location.state?.index;
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState<
    'SUCCESS' | 'PENDING' | 'NO_PROFESSOR' | 'FAILED'
  >('PENDING');
  const [professors, setProfessors] = useState<Professor[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      console.log(reportId);

      try {
        if (reportId) {
          const response = await getReportWithId(reportId); // reportId is guaranteed to be a string now
          if (response && response.applyResultList) {
            const data: ReportResponse = response;
            setReport(response);
            setProfessors(data.applyResultList);
            setLoading(false);
            setStatus(response.status);
          } else {
            console.error('Error: No applyResultList found in response');
            setStatus('FAILED');
          }
        } else {
          setStatus('FAILED');
        }
      } catch (error) {
        console.error('Error fetching report data:', error);
        setStatus('FAILED');
      }
    };

    fetchData();
  }, [reportId]);

  if (loading) {
    return <Loader color1="#56A2FF" color2="#FFF" size={200} />;
  }

  if (status === 'PENDING') {
    return (
      <PendingReport
        index={index}
        country={report.regionList}
        intrests={report.interestList}
        date={report.createdDate}
      />
    );
  }
  if (status === 'NO_PROFESSOR') {
    return (
      <FailedReport
        index={index}
        country={report.regionList}
        intrests={report.interestList}
        date={report.createdDate}
      />
    );
  }

  if (status === 'FAILED') {
    return (
      <FailedReport
        index={index}
        country={report.regionList}
        intrests={report.interestList}
        date={report.createdDate}
      />
    );
  }

  return (
    <ReportData
      index={index}
      country={report.regionList}
      intrests={report.interestList}
      date={report.createdDate}
      professors={professors}
    />
  );
};

export default Report;
