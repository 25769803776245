import React, { useState } from 'react';
import {
  Box,
  Text,
  Image,
  Flex,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import theme from '../../theme';

const MotionBox = motion(Box);

interface AccordionProps {
  id: number;
  question: string;
  answer: string;
  icon: string;
}

const CustomAccordion: React.FC<AccordionProps> = ({
  id,
  question,
  answer,
  icon,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  const handleAccordionChange = (isOpen: boolean) => {
    setIsExpanded(isOpen);
  };

  return (
    <AccordionItem
      borderRadius="8px"
      border="1px solid #EBEBF0"
      mb="12px"
      onChange={() => handleAccordionChange}
      bg="#fff"
    >
      {() => (
        <>
          <h2>
            <AccordionButton
              my={2}
              _hover={{
                bg: 'none',
              }}
              onClick={toggleAccordion}
            >
              <Flex
                flex="1"
                textAlign="right"
                gap="12px"
                align="center"
                justifyContent="space-between"
              >
                <Text
                  fontFamily={theme.fonts.family.medium}
                  fontWeight="500"
                  fontSize={theme.fonts.size.small}
                  color={theme.colors.secondary}
                >
                  {question}
                </Text>
                <Image
                  src={icon}
                  width="16px"
                  transform={isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}
                  transition="transform 0.3s ease-in-out"
                />
              </Flex>
            </AccordionButton>
          </h2>
          <AccordionPanel overflow="hidden" pt={0}>
            <MotionBox
              initial={{ maxHeight: 0 }}
              animate={{ maxHeight: isExpanded ? '500px' : '0px' }}
              transition={{ duration: 0.5 }}
            >
              <Box
                fontFamily={theme.fonts.family.body}
                fontWeight="400"
                color={theme.colors.secondary}
                fontSize={theme.fonts.size.lil}
              >
                <Text align="justify">{answer}</Text>
              </Box>
            </MotionBox>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

export default CustomAccordion;
