// src/components/mobile/GpaResultModal.tsx

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
  Flex,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { ReactComponent as GpaResultImage } from 'src/assets/mobile/images/GpaResultImage.svg';
import theme from 'src/theme';

interface GpaResultModalProps {
  isOpen: boolean;
  onClose: () => void;
  gpa: number;
}

const GpaResultModal: React.FC<GpaResultModalProps> = ({
  isOpen,
  onClose,
  gpa,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent
        borderTopRadius="lg"
        w="100%"
        maxWidth="600px"
        m="0 auto"
        minHeight="200px"
        position="fixed"
        bottom="0"
        borderRadius="8px 8px 0px 0px"
        transition="min-height 0.2s ease-in-out"
      >
        <ModalHeader
          textAlign="center"
          justifyContent="center"
          marginBottom="26px"
        >
          <Flex width="100%" textAlign="center" justifyContent="center">
            <GpaResultImage />
          </Flex>
        </ModalHeader>
        <ModalBody paddingX="16px">
          <Box
            backgroundColor={theme.colors.mobile.mobileblue}
            borderRadius="8px"
            padding="8px 12px"
            marginBottom="24px"
            textAlign="center"
          >
            <Text color="white">معدل نمره شما {gpa.toFixed(2)} ارزیابی شد</Text>
          </Box>
          {/* <Flex
            width="100%"
            textAlign="center"
            justifyContent="center"
            marginBottom="24px"
          >
            <Text>معدل نمره شما {gpa.toFixed(2)} معادل X ارزیابی شد</Text>
          </Flex> */}
          {/* <Box
            backgroundColor={theme.colors.mobile.secondary}
            borderRadius="8px"
            padding="8px 12px"
          >
            <Text>راهنمای 1 , C , B</Text>
          </Box> */}
        </ModalBody>
        <Flex
          width="100%"
          borderRadius="8px"
          justifyContent="center"
          cursor="pointer"
          backgroundColor="#FFFFFF"
          padding="16px"
        >
          <Button
            onClick={onClose}
            width="100%"
            backgroundColor={theme.colors.secondary}
            borderRadius="8px"
            h="48px"
            color={theme.colors.texts.white}
            _hover={{
              backgroundColor: theme.colors.hover,
            }}
          >
            متوجه شدم
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default GpaResultModal;
