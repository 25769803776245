import React, { useContext } from 'react';
import { Box, Heading, Accordion, Flex, Text, Link } from '@chakra-ui/react';
import theme from '../theme';
import PagesHeader from 'src/components/mobile/PagesHeader';
import { AppContext } from '../context/AppContext'; // Adjust the import path as needed
import CustomAccordion from 'src/components/mobile/Accordion';
import { ReactComponent as CallIcon } from 'src/assets/mobile/icons/CallIcon.svg';
import MoreContentIcon from '../assets/mobile/icons/MoreContentIcon.png';

const Faq: React.FC = () => {
  const appContext = useContext(AppContext);

  if (!appContext) {
    return null; // Or some fallback UI
  }

  const { faqContent } = appContext;

  return (
    <Box minH="100vh" backgroundColor={theme.colors.mobile.pbg}>
      <PagesHeader text={`سوالات متداول`} showBackButton={true} />
      <Box paddingX="16px" paddingBottom="80px">
        <Accordion allowToggle allowMultiple>
          {faqContent.map((faq, index) => (
            <CustomAccordion
              key={index}
              id={index}
              question={faq.question}
              answer={faq.answer}
              icon={MoreContentIcon}
            />
          ))}
        </Accordion>
        <Text fontWeight="500" margin="20px 16px 8px auto">
          مشکلم در فهرست بالا نبود
        </Text>
        <Link href="https://t.me/applytory_admin">
          <Box bg="white" borderRadius="8px" boxShadow="sm" paddingX="12px">
            <Flex
              paddingY="12px"
              justify="space-between"
              align="center"
              borderTop="1px solid #E2E8F0"
              fontFamily={theme.fonts.family.body}
              fontSize={theme.fonts.size.small}
            >
              <Flex align="center">
                <CallIcon />
                <Text ml={2} mr="8px">
                  تماس با پشتیبان
                </Text>
              </Flex>

              <Text fontWeight="700" color="#3080E2">
                با ما در تماس باشید
              </Text>
            </Flex>
          </Box>
        </Link>
      </Box>
    </Box>
  );
};

export default Faq;
