import axios from 'axios';
import { API_URL } from './constants';

// Redirect user to Google OAuth2 authorization endpoint
export const redirectToGoogleAuth = async (): Promise<void> => {
  try {
    window.location.replace(`${API_URL}oauth2/authorization/google`);
  } catch (error) {
    console.error('Error handling Google auth callback:', error);
    throw error;
  }
};

// Handle the OAuth2 callback and extract tokens
export const handleGoogleAuthCallback = async (
  urlParams: string
): Promise<string> => {
  try {
    const response = await axios.get(
      `${API_URL}login/oauth2/code/google?${urlParams}`,
      { withCredentials: true }
    );

    console.log('Google auth callback response:', response);

    // Check if the response contains the necessary data
    if (response.data.code !== 0) {
      throw new Error(response.data.message);
    }

    // Check if the body contains accessToken and refreshToken
    const { accessToken, refreshToken, userName } = response.data.body;

    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
    } else {
      throw new Error('Access token is missing in the response');
    }

    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
    } else {
      console.warn('Refresh token is missing in the response');
    }

    if (userName) {
      localStorage.setItem('user', userName);
    } else {
      console.warn('userName is missing in the response');
    }

    return userName;
  } catch (error) {
    console.error('Error handling Google auth callback:', error);
    throw error;
  }
};
