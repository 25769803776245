import axios from 'axios';
import { API_BASE_URL } from './constants';

// Function to fetch interests from the API
export const fetchInterestsAPI = async (
  query: string,
  signal?: AbortSignal
) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/interests/search`, {
      params: { interest: query },
      signal,
    });
    return response.data.body;
  } catch (error) {
    console.error('Error fetching interests:', error);
    throw error;
  }
};
