import React, { ReactNode } from 'react';
import { Box, Container } from '@chakra-ui/react';
import BackgroundImage from '../../assets/images/image.png';
import '../../res_style.css';

interface HeaderContainerProps {
  children: ReactNode;
}

const HeaderContainer: React.FC<HeaderContainerProps> = ({ children }) => {
  return (
    <Box
      mb="88px"
      height={{ base: '1250px', md: '100vh' }}
      display="flex"
      flexDirection="column"
      position="relative"
      bgImage={`url(${BackgroundImage})`}
      bgColor="#333333"
      bgSize="contain"
      bgPosition="left"
      bgRepeat="no-repeat"
      textAlign="center"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        width={{ base: '100%', md: '100%' }} // Full width on mobile
        // bg="#333333"
        zIndex={1}
      />
      <Box
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        width={{ base: '100%', md: '70%' }} // Full width on mobile
        // background="linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2))"
        zIndex={1}
      />
      <Container
        className="header_container_content"
        maxW={{ base: '100%', md: 'container.lg' }}
      >
        {children}
      </Container>
    </Box>
  );
};

export default HeaderContainer;
