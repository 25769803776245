// NewRequestMobilePage.tsx

import React, { useState, useContext, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { AxiosError } from 'axios';
import theme from 'src/theme';
import { useLocation, useNavigate } from 'react-router-dom';

import DropdownIntrestsCheckboxes from 'src/components/mobile/newReq/DropdownIntrestsCheckboxes';
import DropdownCountryCheckboxes from 'src/components/mobile/newReq/DropdownCountryCheckboxes';
import DropdownRankCheckbox from 'src/components/mobile/newReq/DropdownRankCheckbox';
import FileUpload from 'src/components/mobile/newReq/FileUpload';
import Loader from 'src/components/Loader';

import { AppContext } from 'src/context/AppContext';
import { AuthContext } from 'src/context/AuthContext';
import { handleUnauthorizedError } from 'src/services/authService';
import { generateReport, getReportBalance } from 'src/services/reportService';
import PagesHeader from 'src/components/mobile/PagesHeader';
import SuccessModal from 'src/components/mobile/newReq/SuccessModal';
import CustomModal from './modal/CustomModal';
import { usePlan } from 'src/context/PlanContext';

type Option = {
  value: string;
  label: string;
};

const NewRequest: React.FC = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [showCVModal, setShowCVModal] = useState(false);
  const [hasShownCVModal, setHasShownCVModal] = useState(false);

  const { planShowed } = usePlan();

  const [isOutofBallanceOpen, setIsOutofBallanceOpen] = useState(false);
  const [isFirstShow, setIsFirstShow] = useState(false);
  const [reportBalance, setReportBalance] = useState<number>(0);
  const [reportType, setReportType] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [initialSearchData, setInitialSearchData] = useState<{
    interests: string[];
    countries: string;
    universityRank: string;
    file: File | null;
  } | null>(null);

  const appContext = useContext(AppContext);
  const authContext = useContext(AuthContext);

  const toast = useToast({
    position: 'top',
    containerStyle: {
      textAlign: 'center',
      width: '500px',
      maxWidth: '100%',
    },
  });

  // Handlers for the CustomModal
  const handleAddResume = () => {
    // console.log('User chose to add a resume');
    setShowCVModal(false);
  };
  const handleContinueWithoutResume = () => {
    // console.log('Attempting to continue without resume!!!');
    setShowCVModal(false);
    SearchAction();
  };

  // Contexts Fetch
  const {
    destinationCountries,
    universityRankOptions,
    UniversityRankSelected,
    IntrestSelected,
    CountriesSelected,
    setIntrestSelected,
    setCountriesSelected,
    setUniversityRankSelected,
    loading: contextLoading,
  } = appContext || {};
  const { isAuthenticated, user } = authContext || {};

  // Balance Fetch
  const fetchBalance = useCallback(async () => {
    if (user) {
      try {
        const data = await getReportBalance();
        if (data.body && data.body.length > 0) {
          data.body.map((plan) => {
            if (
              plan.reportType.price === 0 &&
              plan.reportBalance === 3 &&
              !planShowed &&
              data.body.length < 2
            ) {
              setIsFirstShow(true);
              navigate(`/plans`, {
                state: { isWelcomePlan: true },
              });
            } else if (
              plan.reportType.price === 0 &&
              plan.reportBalance === 0 &&
              !planShowed &&
              data.body.length < 2
            ) {
              setIsFirstShow(false);
              navigate(`/plans`, {
                state: { isWelcomePlan: false },
              });
            }
          });
          setReportBalance(data.body[0].reportBalance);
          setReportType(data.body[0].reportType.id);
        } else {
          console.error('Invalid response from getReportBalance:', data);
          navigate(`/plans`, {
            state: { isWelcomePlan: false },
          });
        }
      } catch (error) {
        handleUnauthorizedError(error as AxiosError);
        console.error('Error fetching report balance:', error);
      }
    }
  }, [user, navigate, planShowed]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchBalance();
    }
  }, [isAuthenticated, fetchBalance]);

  // Pre Fetch Intrests from Last Input
  useEffect(() => {
    if (initialSearchData && isAuthenticated) {
      if (setIntrestSelected) {
        setIntrestSelected(initialSearchData.interests);
      }
      if (setCountriesSelected) {
        let countryValue = initialSearchData.countries;

        countryValue = initialSearchData.countries;
        setCountriesSelected(countryValue);
      }
      if (setUniversityRankSelected) {
        setUniversityRankSelected(initialSearchData.universityRank);
      }
      setUploadedFile(initialSearchData.file);
      setInitialSearchData(null);
    }
  }, [
    isAuthenticated,
    initialSearchData,
    setIntrestSelected,
    setCountriesSelected,
    setUniversityRankSelected,
  ]);

  // Data Inputs
  const handleFileUpload = (file: File) => {
    // console.log('File uploaded:', file);
    setUploadedFile(file);
  };

  const handleConfirmIntrestSelected = (selectedValues: string[]) => {
    setIntrestSelected && setIntrestSelected(selectedValues);
  };

  const handleConfirmCountriesSelected = (selectedValues: string) => {
    let countryValue = selectedValues;

    countryValue = selectedValues;
    setCountriesSelected && setCountriesSelected(countryValue);
  };

  const handleConfirmUniversityRank = (selectedValue: string) => {
    setUniversityRankSelected && setUniversityRankSelected(selectedValue);
  };

  const fetchDestinationCountries = async (): Promise<Option[]> => {
    return destinationCountries || [];
  };

  const fetchUniversityRank = async (): Promise<Option[]> => {
    return universityRankOptions || [];
  };

  // Seaarch ACtion
  const SearchAction = useCallback(async () => {
    if (
      !IntrestSelected ||
      IntrestSelected.length === 0 ||
      !CountriesSelected ||
      CountriesSelected.length === 0 ||
      !UniversityRankSelected ||
      UniversityRankSelected.length === 0
    ) {
      toast({
        title: 'ورود ناقص اطلاعات',
        description: 'لطفا مقادیر ورودی را مجدد بررسی کنید',
        status: 'error',
        duration: 3000,
        containerStyle: {
          textAlign: 'center',
          fontFamily: `${theme.fonts.family.body}`,
          fontWeight: 'medium',
          backgroundColor: 'black',
          color: 'black',
        },
        isClosable: true,
      });
      return;
    }

    if (!isAuthenticated) {
      setInitialSearchData({
        interests: IntrestSelected || [],
        countries: CountriesSelected || '',
        universityRank: UniversityRankSelected || '',
        file: uploadedFile,
      });
      return;
    }

    if (!uploadedFile && !hasShownCVModal) {
      setShowCVModal(true);
      setHasShownCVModal(true);
      return;
    }
    try {
      const data = await getReportBalance();
      if (data.body && data.body.length > 0) {
        const latestBalance = data.body[0].reportBalance;

        let indexToUse = 0;
        for (let i = 0; i < data.body.length; i++) {
          if (data.body[i].reportType.id === 2) {
            indexToUse = i;
            break;
          }
        }
        const latestReportType = data.body[indexToUse].reportType.id;
        setReportBalance(latestBalance);
        setReportType(latestReportType);

        if (latestBalance <= 0) {
          navigate(`/plans`, {
            state: { isWelcomePlan: true },
          });
          return;
        }

        let minUniRank = 0;
        let maxUniRank = 0;

        if (UniversityRankSelected) {
          const selectedRank = UniversityRankSelected;
          const rankRange = selectedRank.split(' - ');
          minUniRank =
            rankRange[0] === '∞' ? Infinity : parseInt(rankRange[0], 10);
          maxUniRank =
            rankRange[1] === '∞' ? Infinity : parseInt(rankRange[1], 10);
        }

        const filter = {
          reportTypeId: latestReportType,
          interestList: IntrestSelected || [],
          minUniRank: minUniRank,
          maxUniRank: maxUniRank,
          regionList: CountriesSelected ? [CountriesSelected] : [''],
        };

        setLoading(true);
        const reportData = await generateReport(uploadedFile, filter);
        // console.log('Report generated successfully:', reportData);
        onOpen();

        setIntrestSelected && setIntrestSelected([]);
        setCountriesSelected && setCountriesSelected('');
        setUniversityRankSelected && setUniversityRankSelected('');
        setUploadedFile(null);
      } else {
        console.error('Invalid response from getReportBalance:', data);
        setIsOutofBallanceOpen(true);
        navigate(`/plans`, {
          state: { isWelcomePlan: true },
        });
      }
    } catch (error) {
      console.error('Error generating report:', error);
    } finally {
      setLoading(false);
    }
  }, [
    isAuthenticated,
    IntrestSelected,
    CountriesSelected,
    UniversityRankSelected,
    uploadedFile,
    toast,
    setIntrestSelected,
    setCountriesSelected,
    setUniversityRankSelected,
  ]);

  useEffect(() => {
    const handlePostAuthentication = async () => {
      if (isAuthenticated && initialSearchData) {
        await fetchBalance();
        await SearchAction();
      }
    };

    handlePostAuthentication();
  }, [isAuthenticated, initialSearchData, fetchBalance, SearchAction]);

  if (!appContext || !authContext) {
    return null;
  }

  const allFieldsFilled =
    IntrestSelected &&
    IntrestSelected.length > 0 &&
    CountriesSelected &&
    CountriesSelected.length > 0 &&
    UniversityRankSelected &&
    UniversityRankSelected.length > 0;

  return (
    <Flex
      position="relative"
      flexDirection="column"
      zIndex="1"
      backgroundColor={theme.colors.mobile.pbg}
      minHeight="100vh"
      paddingTop="56px"
      paddingBottom="80px"
    >
      <PagesHeader text={'درخواست جدید'} showBackButton />

      {(loading || contextLoading) && (
        <Loader color1="#56A2FF" color2="#FFF" size={100} />
      )}
      <Box
        width="100%"
        textAlign="start"
        mb="20px"
        position="relative"
        zIndex="1"
        padding="16px"
      >
        <Text
          color="#57575A"
          fontFamily={theme.fonts.family.medium}
          fontSize={theme.fonts.size.small}
          fontWeight="500"
          marginBottom="24px"
        >
          برای جستجوی لیست اساتید اطلاعات زیر را وارد نمایید
        </Text>
        <DropdownIntrestsCheckboxes
          placeholder="لیست علاقه مندی ها"
          onConfirm={handleConfirmIntrestSelected}
        />
        <DropdownCountryCheckboxes
          fetchOptions={fetchDestinationCountries}
          placeholder="مقصد"
          onConfirm={handleConfirmCountriesSelected}
        />
        <DropdownRankCheckbox
          fetchOptions={fetchUniversityRank}
          placeholder="رنک دانشگاه"
          onConfirm={handleConfirmUniversityRank}
        />
        <FileUpload onFileUpload={handleFileUpload} />
      </Box>

      <Flex
        position="fixed"
        bottom="0"
        // left="0"
        width="100%"
        backgroundColor="#FFFFFF"
        padding="16px"
        maxWidth="600px"
        transform="translateX(-50%)"
        left="50%"
        // boxShadow={'0 2px 4px rgba(0, 0, 0, 0.1)'}
      >
        <Button
          onClick={SearchAction}
          width="100%"
          backgroundColor={allFieldsFilled ? theme.colors.secondary : '#EBEBF0'}
          borderRadius="8px"
          h="48px"
          color={
            allFieldsFilled ? theme.colors.texts.white : theme.colors.secondary
          }
          _hover={{
            backgroundColor: allFieldsFilled
              ? theme.colors.secondary
              : '#EBEBF0',
            color: allFieldsFilled
              ? theme.colors.texts.white
              : theme.colors.secondary,
          }}
        >
          ثبت درخواست
        </Button>
      </Flex>
      <SuccessModal isOpen={isOpen} onClose={onClose} />
      {showCVModal && (
        <CustomModal
          isOpen={showCVModal}
          onClose={() => setShowCVModal(false)}
          title="فایل رزومه"
          body="با ارسال رزومه می‌توانید نتیجه بهتری دریافت کنید."
          buttons={[
            {
              text: 'اضافه کردن رزومه',
              onClick: handleAddResume,
            },
            {
              text: 'ادامه',
              onClick: handleContinueWithoutResume,
            },
          ]}
        />
      )}
    </Flex>
  );
};

export default NewRequest;
