import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Header from 'src/containers/header/Header';
import SecHeaderNav from 'src/containers/header/SecHeaderNav';
import Footer from 'src/containers/Footer';
import Home from 'src/pages/Home';
import Faq from 'src/pages/faq';
import FaqMobile from 'src/mobile/faq';

import GoogleAuthCallback from 'src/mobile/GoogleAuthCallback';
import Login from 'src/mobile/Login';
import AboutUs from 'src/pages/AboutUs';
import AboutUsMobile from 'src/mobile/AboutUs';
import ContactUs from 'src/pages/ContactUs';
import LoginError from 'src/mobile/LoginError';
import MoreDetails from 'src/pages/MoreDetails';
import { PrivateRoute } from 'src/pages/PrivateRoutes';
import NewRequest from 'src/mobile/NewRequest';
import { AuthContext } from 'src/context/AuthContext';
import { Box } from '@chakra-ui/react';
import LastReports from 'src/mobile/LastReports';
import Profile from 'src/mobile/Profile';
import UserPlans from 'src/mobile/UserPlans';
import UserTransactions from 'src/mobile/UserTransactions';
import Plans from 'src/mobile/Plans';
import BuyPlans from 'src/mobile/BuyPlans';
import Payment from 'src/mobile/Payment';
import Report from 'src/mobile/Report';
import PaymentResult from 'src/mobile/PaymentResult';
import GpaCalculatorPage from 'src/mobile/GpaCalculatorPage';
import Loader from 'src/components/Loader';
import NotFound from 'src/mobile/NotFound';

const DesktopLayout: React.FC = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Loader color1="#56A2FF" color2="#404040" size={100} />
      </Box>
    );
  }
  const { isAuthenticated, isLoading } = authContext;

  return (
    <>
      <Routes>
        {/* Dashboard */}
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />}
        />
        <Route
          path="/login/google"
          element={
            <>
              <GoogleAuthCallback />
            </>
          }
        />
        <Route path="/login/error" element={<LoginError />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <LastReports />
            </PrivateRoute>
          }
        />
        <Route
          path="/new-request"
          element={
            <PrivateRoute>
              <NewRequest />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/plans"
          element={
            <PrivateRoute>
              <UserPlans />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/transactions"
          element={
            <PrivateRoute>
              <UserTransactions />
            </PrivateRoute>
          }
        />
        <Route
          path="/plans"
          element={
            <PrivateRoute>
              <Plans />
            </PrivateRoute>
          }
        />
        <Route
          path="/purchase-plans"
          element={
            <PrivateRoute>
              <BuyPlans />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/:planID"
          element={
            <PrivateRoute>
              <Payment />
            </PrivateRoute>
          }
        />
        <Route
          path="/report/:reportId"
          element={
            <PrivateRoute>
              <Report />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/faq"
          element={
            <PrivateRoute>
              <FaqMobile />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment-result"
          element={
            <PrivateRoute>
              <PaymentResult />
            </PrivateRoute>
          }
        />

        <Route
          path="/profile/aboutus"
          element={
            <PrivateRoute>
              <AboutUsMobile />
            </PrivateRoute>
          }
        />
        <Route
          path="/gpa-calculator"
          element={
            <PrivateRoute>
              <GpaCalculatorPage />
            </PrivateRoute>
          }
        />

        {/* Desktop */}
        <Route
          path="/"
          element={
            <>
              <Header />
              <Home />
              <Footer />
            </>
          }
        />
        <Route
          path="/plans"
          element={
            <>
              <Header />
              <Home />
              <Footer />
            </>
          }
        />

        <Route
          path="/faq"
          element={
            <>
              <SecHeaderNav />
              <Faq />
              <Footer />
            </>
          }
        />
        <Route
          path="/aboutus"
          element={
            <>
              <SecHeaderNav />
              <AboutUs />
              <Footer />
            </>
          }
        />
        <Route
          path="/moredetails"
          element={
            <>
              <SecHeaderNav />
              <MoreDetails />
              <Footer />
            </>
          }
        />
        <Route
          path="/contactus"
          element={
            <>
              <SecHeaderNav />
              <ContactUs />
              <Footer />
            </>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default DesktopLayout;
