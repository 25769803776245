import axios, { AxiosError } from 'axios';
import { API_BASE_URL } from './constants';
import { handleUnauthorizedError } from './authService';

const REPORT_TYPE_URL = `${API_BASE_URL}/report-type/`;

export const getPlanData = async () => {
  try {
    const response = await axios.get(REPORT_TYPE_URL);
    return response.data.body.content;
  } catch (error) {
    console.error('Error fetching plan data:', error);
    handleUnauthorizedError(error as AxiosError);
    throw error;
  }
};
