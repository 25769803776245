import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  VStack,
  Text,
  CloseButton,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { ReactComponent as TagImage } from 'src/assets/mobile/images/Tag.svg';
import theme from 'src/theme';
import { AuthContext } from 'src/context/AuthContext';
import axios, { AxiosError } from 'axios';
import Loader from 'src/components/Loader';
import { getPlanData } from 'src/services/priceService';
import { handleUnauthorizedError } from 'src/services/authService';
import { useLocation, useNavigate } from 'react-router-dom';

interface Plan {
  id: number;
  price: number;
  reportPerPayment: number;
  resultLimit: number;
}

const BuyPlans: React.FC = () => {
  const location = useLocation();
  const isWelcomePlan = location.state?.isWelcomePlan;

  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [freePlan, setFreePlan] = useState<Plan | null>(null);
  const [pricedPlan, setPricedPlan] = useState<Plan | null>(null);
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const { isAuthenticated } = useContext(AuthContext) || {};

  const handlePlanSelect = (plan: Plan) => {
    if (!isAuthenticated) {
      setAuthModalOpen(true);
      return;
    }
    if (selectedPlan && selectedPlan.id === plan.id) {
      setSelectedPlan(null);
    } else {
      setSelectedPlan(plan);
    }
  };

  const handlePayment = () => {
    if (!isAuthenticated) {
      setAuthModalOpen(true);
      return;
    }
    // Handle payment logic here
    console.log('Proceed to payment for plan:', selectedPlan);
    if (isWelcomePlan === true) {
      if (selectedPlan?.price === 0) {
        const planShowed = true;
        navigate('/new-request', {
          state: { planShowed },
        });
      } else {
        navigate(`/payment/${selectedPlan?.id}`, {
          state: { selectedPlan },
        });
      }
    } else {
      navigate(`/payment/${selectedPlan?.id}`, {
        state: { selectedPlan },
      });
    }
  };

  const fetchPlanData = async () => {
    setLoading(true);
    try {
      const data = await getPlanData();

      // Separate free and priced plans
      const freePlan = data.find((plan: Plan) => plan.price === 0);
      const pricedPlan = data.find((plan: Plan) => plan.price !== 0);

      setFreePlan(freePlan || null);
      setPricedPlan(pricedPlan || null);
    } catch (error) {
      console.error('Error fetching plan data:', error);
      handleUnauthorizedError(error as AxiosError);
      toast({
        title: 'Plan data error',
        description: (error as AxiosError).message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlanData();
  }, []);

  return (
    <Box borderRadius="md" boxShadow="md" bg="#f6f9fa" minH="100vh">
      <Box
        backgroundColor="#fff"
        paddingBottom="24px"
        borderRadius="0px 0px 12px 12px"
        marginBottom="24px"
        paddingX="16px"
      >
        <Box
          alignContent="center"
          height="56px"
          minHeight="56px"
          marginBottom="24px"
          onClick={() => navigate('/profile')}
        >
          <CloseButton />
        </Box>
        <Flex
          width="100%"
          textAlign="center"
          align="center"
          justifyContent="center"
          marginBottom="24px"
        >
          <TagImage />
        </Flex>
        <Box justifyContent="space-between" textAlign="center">
          <>
            <Text marginBottom="24px">از همراه شما بودن خوشحالیم</Text>
            <Box
              wordBreak="break-word"
              whiteSpace="normal"
              width="100%"
              textAlign="start"
              backgroundColor={theme.colors.mobile.secondary}
              fontFamily={theme.fonts.family.body}
              fontWeight="500"
              fontSize={theme.fonts.size.small}
              color={theme.colors.secondary}
              paddingX="16px"
              borderRadius="8px"
              padding="12px 16px"
              marginTop="24px"
              marginBottom="16px"
            >
              <Text textAlign="justify">
                با خرید اشتراک از تمام امکانات اپ لذت ببرید و به لیست گسترده
                اساتید مرتبط با شما دسترسی داشته باشید
              </Text>
            </Box>
          </>
        </Box>
      </Box>
      <VStack paddingX="16px" spacing="16px">
        {loading ? (
          <Loader color1="#56A2FF" color2="#FFF" size={200} />
        ) : (
          <>
            {pricedPlan && (
              <Box
                key={pricedPlan.id}
                padding="16px"
                borderWidth={1}
                borderColor={
                  selectedPlan && selectedPlan.id === pricedPlan.id
                    ? '#3080E2'
                    : '#EBEBF0'
                }
                borderRadius="16px"
                width="100%"
                textAlign="center"
                backgroundColor="#fff"
                onClick={() => handlePlanSelect(pricedPlan)}
                cursor="pointer"
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="start"
                  marginBottom="8px"
                >
                  <Text
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.semismall}
                    fontWeight="600"
                  >
                    اشتراک بتا
                  </Text>
                  <Box bg="#3080E2" borderRadius="20px" color="#fff">
                    <Text
                      fontFamily={theme.fonts.family.body}
                      fontSize={theme.fonts.size.vlil}
                      fontWeight="500"
                      padding="5px 12px"
                    >
                      پیشنهاد ما
                    </Text>
                  </Box>
                </Flex>
                <Flex justifyContent="space-between" alignItems="start">
                  <Text
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.small}
                    fontWeight="400"
                  >
                    {/* {` امکان جستجوی استاد در ${pricedPlan.reportPerPayment} گزارش `} */}
                    مدت زمان استفاده یک هفته
                  </Text>
                  <Text
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.small}
                    fontWeight="500"
                  >
                    {`${pricedPlan.price.toLocaleString()} ریال`}
                  </Text>
                </Flex>
              </Box>
            )}
            {freePlan && (
              <Box
                key={freePlan.id}
                padding="16px"
                borderWidth={1}
                borderRadius="16px"
                width="100%"
                textAlign="center"
                backgroundColor={isWelcomePlan ? '#fff' : '#EBEBF0'}
                cursor={isWelcomePlan ? 'pointer' : 'not-allowed'}
                opacity={isWelcomePlan ? 1 : 0.6}
                onClick={() =>
                  isWelcomePlan ? handlePlanSelect(freePlan) : null
                }
                borderColor={
                  isWelcomePlan
                    ? selectedPlan && selectedPlan.id === freePlan.id
                      ? '#3080E2'
                      : '#EBEBF0'
                    : '#EBEBF0'
                }
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="start"
                  marginBottom="8px"
                >
                  <Text
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.semismall}
                    fontWeight="600"
                  >
                    اشتراک رایگان
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" alignItems="start">
                  <Text
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.small}
                    fontWeight="400"
                  >
                    {/* {` امکان جستجوی استاد در ${freePlan.reportPerPayment} گزارش `} */}
                    مدت زمان استفاده یک هفته
                  </Text>
                  <Text
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.small}
                    fontWeight="500"
                  >
                    0 ریال
                  </Text>
                </Flex>
              </Box>
            )}
          </>
        )}
      </VStack>
      <Flex
        position="fixed"
        bottom="0"
        width="100%"
        backgroundColor="#FFFFFF"
        padding="16px"
        maxWidth="600px"
        transform="translateX(-50%)"
        left="50%"
        boxShadow="0px 1px 0px 0px #E6E6E6 inset"
      >
        <Button
          onClick={selectedPlan ? handlePayment : undefined}
          cursor={selectedPlan ? 'pointer' : 'auto'}
          width="100%"
          backgroundColor={selectedPlan ? theme.colors.secondary : '#EBEBF0'}
          borderRadius="8px"
          h="48px"
          color={
            selectedPlan ? theme.colors.texts.white : theme.colors.secondary
          }
          _hover={{
            backgroundColor: selectedPlan ? theme.colors.secondary : '#EBEBF0',
            color: selectedPlan
              ? theme.colors.texts.white
              : theme.colors.secondary,
          }}
        >
          تایید
        </Button>
      </Flex>
    </Box>
  );
};

export default BuyPlans;
