import React from 'react';
import './CustomRadiobox.css';
import { Text } from '@chakra-ui/react';

interface CustomRadioboxProps {
  isChecked: boolean;
  label: string;
  onChange: () => void;
  disabled?: boolean;
}

const CustomRadiobox: React.FC<CustomRadioboxProps> = ({
  isChecked,
  label,
  onChange,
  disabled = false,
}) => {
  return (
    <label
      className={`custom-radiobox-container ${disabled ? 'disabled' : ''}`}
    >
      <input
        type="radio"
        checked={isChecked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className="radiomark"></span>
      {label}
    </label>
  );
};

export default CustomRadiobox;
