import React, { createContext, useState, ReactNode } from 'react';
import {
  AppContextType,
  AboutUsContentType,
  MoreDetailsContentType,
} from 'src/@types/AppContextType';
import regions from 'src/data/regions.json';
// import Image1 from 'public/assets/images/image1.png';
// import Resume from 'public/assets/images/resume.png';

const AppContext = createContext<AppContextType | null>(null);

const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [navMenuTitles] = useState<string[]>([
    'خانه',
    'جستجوهای اخیر',
    'وبلاگ',
    'سوالات متداول',
    'درباره ما',
    'انواع اشتراک',
  ]);

  const [headerContent] = useState({
    title1: 'اپلای راحت‌تر از قبل',
    title2: 'برای شما، بر اساس علاقه و توانمندی دانشگاه ',
    description:
      'ما در اپلای طوری لیستی از اساتید کاملا منطبق با رزومه و علایق را متناسب با شما ارائه می‌دهیم',
    learnMore: 'بیشتر بدانید',
    searchPlaceholder: 'جستجو کنید',
  });

  const [fieldsOfInterest, setFieldsOfInterest] = useState<
    { value: string; label: string }[]
  >([]);
  const [loading, setLoading] = useState(false);

  const regionsArray: string[] = regions as string[];

  const destinationCountries = regionsArray
    .map((region) => ({
      value: region,
      label: region,
    }))
    .sort((a, b) => a.value.localeCompare(b.value));

  const [universityRankOptions] = useState<{ value: string; label: string }[]>([
    { value: '0 - 100', label: '100 - 0' },
    { value: '101 - 500', label: '500 - 101' },
    { value: '501 - 1000', label: '1000 - 501' },
    { value: '1000 - ∞', label: '∞ - 1000' },
  ]);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [UniversityRankSelected, setUniversityRankSelected] = useState<
    string | null
  >(null);
  const [IntrestSelected, setIntrestSelected] = useState<string[] | null>(null);
  const [CountriesSelected, setCountriesSelected] = useState<string | null>(
    null
  );

  const aboutUsContent: AboutUsContentType = {
    heading: 'درباره ما',
    sections: [
      {
        image: '/src/assets/images/image1.png',
        texts: [
          'اگر جزو قشر در حال تحصیل باشید، احتمالا حداقل یک بار در عمرتان به موضوع مهاجرت تحصیلی فکر کرده‌اید. سوال‌هایی که ممکن است در این مسیر ذهن شما را مشغول کند، عبارتند از:',
          'چطور اپلای کنیم؟',
          'کدام دانشگاه برای اپلای بهتر است؟',
          'کدام اساتید با علایق و رزومه کاری من منطبق هستند؟',
          'یکی از مهم‌ترین قدم‌ها در فرآیند اپلای، مکاتبه با اساتید دانشگاه‌های خارجی است. جلب نظر اساتید با رزومه تحصیلی خود می‌تواند مسیر دریافت پذیرش تحصیلی را برایتان هموارتر کند. این مرحله به خصوص در مقطع ارشد و دکتری بسیار حیاتی است.',
          'برای موفقیت در این کار، نیاز به لیست کاملی از اساتید مرتبط با رشته تحصیلی خود دارید. پیدا کردن این لیست در دانشگاه‌های خارجی، فرآیندی زمان‌بر و تخصصی است.',
        ],
      },
    ],
    finalText:
      'در این راستا، ApplyTory با توجه به رشته و زمینه تحصیلی، علایق و تجربیات کاری شما، لیستی از اساتید مرتبط در کشور مورد نظر شما را ارائه می‌دهد. با استفاده از این لیست، می‌توانید با سرعت و دقت بیشتری به مکاتبه با اساتید خارجی پرداخته و فرآیند اپلای تحصیلی خود را بهبود بخشید.',
  };

  const moreDetailsContent: MoreDetailsContentType = {
    heading: 'بیشتر بدانید',
    steps: [
      {
        title: 'چگونه شروع کنیم؟',
        steps: [
          'ثبت نام: با استفاده از ایمیل یا حساب گوگل و یا ایمیل ثبت نام کنید.',
          'آپلود رزومه: فایل PDF رزومه خود را آپلود کنید. دقت داشته باشید که رزومه باید استاندارد و به زبان انگلیسی باشد. فرمت استاندارد رزومه شامل موارد زیر است:',
          'انتخاب جزئیات: کشور مقصد و حدود رتبه دانشگاه مورد نظر خود را از لیست انتخاب کنید.',
          'انتخاب علایق پژوهشی: در این مرحله بهتر است از کلمات کلیدی رایج و مرتبط با رشته تحصیلی خود استفاده کنید. این مرحله کمک می‌کند تا با دقت بیشتری علایق شما را با زمینه‌های پژوهشی اساتید تطبیق دهیم.',
          'دریافت لیست: لیست اساتید مرتبط را دریافت و شروع به مکاتبه کنید.',
        ],
        resume: {
          image: 'assets/images/resume.png',
          items: [
            'نام کامل، آدرس ایمیل، شماره تلفن',
            'تحصیلات: جزئیات مدارک تحصیلی، نام دانشگاه، تاریخ فارغ‌التحصیلی',
            'تجربیات کاری: تاریخچه شغلی مرتبط با رشته تحصیلی، نام شرکت‌ها، موقعیت‌های شغلی و وظایف',
            'مهارت‌ها: مهارت‌های فنی و نرم‌افزاری مرتبط',
            'انتشارات و مقالات: فهرست مقالات، کنفرانس‌ها و پژوهش‌های علمی',
            'جوایز و افتخارات: جوایز و افتخارات علمی و تحصیلی',
          ],
        },
        researchInterests: [
          {
            field: 'مهندسی نرم‌افزار',
            keywords: [
              'Software Engineering',
              'Machine Learning',
              'Artificial Intelligence',
            ],
          },
          {
            field: 'فیزیک',
            keywords: ['Quantum Mechanics', 'Particle Physics', 'Astrophysics'],
          },
        ],
      },
    ],
  };

  const faqContent = [
    {
      question: ' ApplyTory چیست؟',
      answer:
        'اپلای‌طوری پلتفرم آنلاین است که به دانشجویان کمک می‌کند تا با اساتید دانشگاه‌های مختلف در سراسر جهان بر اساس رشته تحصیلی و رزومه خود ارتباط برقرار کنند. هدف اصلی این پلتفرم تسهیل فرآیند اپلای برای دریافت پذیرش تحصیلی و فاند است.',
    },
    {
      question: 'چگونه می‌توانم در ApplyTory ثبت نام کنم؟',
      answer:
        'برای ثبت نام در ApplyTory، می‌توانید از ایمیل یا حساب گوگل خود استفاده کنید. پس از ورود، یک کد تأیید به ایمیل شما ارسال می‌شود که باید آن را وارد کنید تا به راحتی ثبت نام شما کامل شود',
    },
    {
      question: 'چه نوع رزومه‌ای باید آپلود کنم؟',
      answer:
        'رزومه‌ای که آپلود می‌کنید باید به زبان انگلیسی و در قالب PDF باشد. رزومه بهتر است شامل اطلاعات شخصی، تحصیلات، تجربیات کاری، مهارت‌ها، انتشارات و مقالات، و جوایز و افتخارات باشد.',
    },
    {
      question: 'چرا باید علایق پژوهشی خود را انتخاب کنم؟',
      answer:
        'انتخاب دقیق علایق پژوهشی به ApplyTory کمک می‌کند تا بهترین تطابق‌ها را بین شما و اساتید در لیست نهایی به شما ارائه شود. با وارد کردن علایق خود به صورت دقیق، ما می‌توانیم اساتیدی را پیدا کنیم که در زمینه‌های مورد علاقه شما فعالیت می‌کنند، که این موضوع شانس شما برای دریافت پاسخ مثبت را افزایش می‌دهد.',
    },
    {
      question: 'چگونه می‌توانم علایق پژوهشی خود را انتخاب کنم؟',
      answer:
        'با وارد کردن کلمات کلیدی رایج و مرتبط با رشته تحصیلی خود و انتخاب آن ها از لیست میتوانید علایق خود را انتخاب کنید',
    },
    {
      question:
        'آیا می‌توانم قبل از ارتقا به نسخه PRO، لیست اساتید را مشاهده کنم؟',
      answer:
        'بله، در نسخه رایگان ApplyTory می‌توانید به لیست محدودی از اساتید به صورت رایگان دسترسی داشته باشید. برای دسترسی به لیست کامل و امکانات بیشتر، می‌توانید به نسخه PRO ارتقا دهید.',
    },
    {
      question: 'چگونه می‌توانم با تیم پشتیبانی تماس بگیرم؟',
      answer:
        'برای سوالات بیشتر یا دریافت راهنمایی، می‌توانید از طریق ایمیل یا شبکه‌های اجتماعی با تیم پشتیبانی ما با شماره ……………در تماس باشید. اطلاعات تماس ما در بخش "تماس با ما" وب‌سایت موجود است.',
    },
    {
      question: 'چگونه لیست اساتید به‌روزرسانی می‌شود؟',
      answer:
        'بانک اطلاعاتی اساتید ما روزانه به‌روزرسانی می‌شود تا مطمئن شویم که جدیدترین اطلاعات در اختیار کاربران قرار می‌گیرد.',
    },
    {
      question: 'چقدر طول می‌کشد تا لیست اساتید را دریافت کنم؟',
      answer:
        'در سریع‌ترین زمان ممکن، لیست اساتید در کمتر از 24 ساعت برای شما آماده و ارسال می‌شود.',
    },
    {
      question: 'آیا لیست اساتید شخصی‌سازی شده است؟',
      answer:
        'بله، لیست اساتید بر اساس علایق پژوهشی و سوابق موجود در رزومه شما به صورت شخصی‌سازی شده و منحصر به فرد برای شما تهیه می‌شود.',
    },
    {
      question: 'آیا به تاریخچه جستجوهای قبلی دسترسی دارم؟',
      answer:
        'بله، شما می‌توانید از طریق اکانت کاربری خود به تاریخچه جستجوهای قبلی‌تان دسترسی داشته باشید.',
    },
  ];

  const contextValue: AppContextType = {
    navMenuTitles,
    headerContent,
    fieldsOfInterest,
    destinationCountries,
    universityRankOptions,
    selectedCheckboxes,
    setSelectedCheckboxes,
    UniversityRankSelected,
    setUniversityRankSelected,
    IntrestSelected,
    setIntrestSelected,
    CountriesSelected,
    setCountriesSelected,
    loading,
    aboutUsContent,
    moreDetailsContent,
    faqContent,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export { AppContext, AppProvider };
