import React from 'react';
import { ChakraProvider, Box, Text, Image, VStack } from '@chakra-ui/react';
import { ReactComponent as EmptyState } from 'src/assets/mobile/images/EmptyState.svg';
import theme from 'src/theme';

const NoReportFound: React.FC = () => {
  return (
    <Box
      textAlign="center"
      bg="#F7FAFC"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt="63px"
      backgroundColor={theme.colors.mobile.pbg}
    >
      <VStack spacing={4}>
        <Box mb="24px">
          <EmptyState width="130px" />
        </Box>
        <Text
          fontFamily={theme.fonts.family.medium}
          fontWeight="500"
          fontSize={theme.fonts.size.small}
          backgroundColor={theme.colors.mobile.primary}
          color={theme.colors.mobile.texts.primary}
        >
          تا به حال درخواستی نداشته‌اید.
        </Text>
        <Text
          fontFamily={theme.fonts.family.body}
          fontWeight="400"
          fontSize={theme.fonts.size.small}
          textAlign="center"
          backgroundColor={theme.colors.mobile.secondary}
          p="8px 12px"
          borderRadius="8px"
        >
          از طریق منو زیر به راحتی درخواست خود را ثبت نمایید تا در اولین فرصت
          لیست اساتید در اختیار شما قرار داده شود.
        </Text>
      </VStack>
    </Box>
  );
};

export default NoReportFound;
