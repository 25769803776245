import React from 'react';
import './CustomCheckbox.css';

interface CustomCheckboxProps {
  isChecked: boolean;
  label: string;
  onChange: () => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  isChecked,
  label,
  onChange,
}) => {
  return (
    <label className="custom-checkbox-container">
      <input type="checkbox" checked={isChecked} onChange={onChange} />
      <span className="checkmark"></span>
      {label}
    </label>
  );
};

export default CustomCheckbox;
