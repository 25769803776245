// src/utils/toastService.js
import { createStandaloneToast } from '@chakra-ui/react';
import theme from '../theme';

const toast = createStandaloneToast({
  position: 'top',
  containerStyle: {
    textAlign: 'center',
    width: '500px',
    maxWidth: '100%',
  },
}).toast;

const showToast = ({ description, status }) => {
  toast({
    description,
    position: 'top',
    status: status,
    duration: 3000,
    containerStyle: {
      textAlign: 'center',
      fontFamily: `${theme.fonts.family.body}`,
      fontWeight: 'medium',
    },
    isClosable: true,
  });
};

export default showToast;
