import { useMediaQuery } from '@chakra-ui/react';
import { useState, useEffect } from 'react';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [mobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    setIsMobile(mobile);
    setIsLoading(false);
  }, [mobile]);

  return { isMobile, isLoading };
};

export default useIsMobile;
