import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Box,
  Flex,
  Button,
  VStack,
  Text,
  HStack,
  Collapse, // Import Collapse for animation control
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { ReactComponent as CloseModalIcon } from 'src/assets/mobile/icons/CloseModal.svg';
import { ReactComponent as SelectionControlsChecked } from 'src/assets/mobile/icons/SelectionControlsChecked.svg';
import { ReactComponent as SelectionControls } from 'src/assets/mobile/icons/SelectionControls.svg';
import { ReactComponent as DropDownIcon } from 'src/assets/mobile/icons/DropDownIcon.svg';

import theme from 'src/theme';

interface GpaDegreeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (degree: string) => void; // New prop to handle confirmation
}

const GpaDegreeModal: React.FC<GpaDegreeModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  const [selectedDegree, setSelectedDegree] = useState<string>('');
  const [showOptions, setShowOptions] = useState(false);
  const [animateOptions, setAnimateOptions] = useState(false);

  useEffect(() => {
    if (showOptions) {
      // Delay the visibility of the VStack slightly after the modal begins to expand
      const timer = setTimeout(() => {
        setAnimateOptions(true);
      }, 200); // 100ms delay
      return () => clearTimeout(timer);
    } else {
      setAnimateOptions(false);
    }
  }, [showOptions]);

  const handleDegreeChange = (degree: string) => {
    // console.log('Attempting to change degree to:', degree);
    setSelectedDegree(degree);
    setShowOptions(false); // Optionally close dropdown
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent
        borderTopRadius="lg"
        w="100%"
        maxWidth="600px"
        m="0 auto"
        minHeight={showOptions ? '350px' : '250px'}
        position="fixed"
        bottom="0"
        borderRadius="8px 8px 0px 0px"
        transition="min-height 0.2s ease-in-out"
      >
        <Box position="absolute" top="24px" left="24px" onClick={onClose}>
          <CloseModalIcon />
        </Box>
        <ModalHeader textAlign="start" height="72px" minHeight="72px">
          انتخاب مقطع
        </ModalHeader>
        <ModalBody>
          <Box onClick={() => setShowOptions(!showOptions)}>
            <HStack
              backgroundColor="white"
              borderWidth="1px"
              borderRadius="md"
              spacing="0px"
              textAlign="start"
              p={selectedDegree ? '6px 12px' : '16px 12px'}
              marginBottom="5px"
              cursor="pointer"
            >
              <Text width="100%" color={selectedDegree ? '#A3A3A3' : '#57575A'}>
                مقطع تحصیلی
                <Text color="#404040">{selectedDegree}</Text>
              </Text>
              <DropDownIcon />
            </HStack>
            <Collapse in={animateOptions} animateOpacity>
              <VStack
                backgroundColor="white"
                borderWidth="1px"
                borderRadius="md"
                spacing="0px"
              >
                {['ارشد', 'کارشناسی'].map((degree) => (
                  <Flex
                    key={degree}
                    p="16px 12px"
                    width="100%"
                    onClick={() => handleDegreeChange(degree)}
                    justifyContent="space-between"
                    _hover={{ backgroundColor: 'rgba(86, 162, 255,0.1)' }}
                    cursor="pointer"
                  >
                    <Text>{degree}</Text>
                    {selectedDegree === degree ? (
                      <SelectionControlsChecked />
                    ) : (
                      <SelectionControls />
                    )}
                  </Flex>
                ))}
              </VStack>
            </Collapse>
          </Box>
        </ModalBody>

        <Flex
          width="100%"
          borderRadius="8px"
          justifyContent="center"
          cursor="pointer"
          backgroundColor="#FFFFFF"
          padding="16px"
          boxShadow="0px 1px 0px 0px #E6E6E6 inset"
        >
          <Button
            width="100%"
            borderRadius="8px"
            h="48px"
            onClick={() => {
              if (selectedDegree === '') {
                // Do nothing
              } else {
                onClose();
                onConfirm(selectedDegree);
              }
            }}
            backgroundColor={
              selectedDegree === '' ? '#EBEBF0' : theme.colors.secondary
            }
            color={
              selectedDegree === ''
                ? theme.colors.secondary
                : theme.colors.texts.white
            }
            _hover={{
              backgroundColor:
                selectedDegree === '' ? '#EBEBF0' : theme.colors.hover,
            }}
            cursor={selectedDegree === '' ? 'auto' : 'pointer'}
            disabled={selectedDegree === '' ? true : false}
          >
            ثبت درخواست
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default GpaDegreeModal;
