import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import configureAxios from './utils/axiosConfig';
import * as serviceWorkerRegistration from './serviceworker';
import reportWebVitals from './reportWebVitals';

// import './fonts.css';

configureAxios();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
if ('serviceWorker' in navigator) {
  // Register a service worker hosted at the root of the
  // site using the default scope.
  navigator.serviceWorker
    .register(`${process.env.PUBLIC_URL}/service-worker.js`)
    .then(
      (registration) => {
        console.log('Service worker registration succeeded:', registration);
      },
      /*catch*/ (error) => {
        console.error(`Service worker registration failed: ${error}`);
      }
    );
} else {
  console.error('Service workers are not supported.');
}
root.render(
  <>
    <App />
  </>
);
serviceWorkerRegistration.register();

reportWebVitals();
