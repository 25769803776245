// src/services/gpaService.ts

import axios from 'axios';
import { API_BASE_URL } from './constants';

interface GpaRequestBody {
  educationLevel: string;
  courseNames: string;
  creditHours: string;
  grades: string;
}

interface GpaResponse {
  time: string;
  code: number;
  message: string;
  body: {
    gpa: number;
  };
}

export const calculateGpa = async (
  data: GpaRequestBody
): Promise<GpaResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/gpa`, data);
    return response.data;
  } catch (error) {
    console.error('Error calculating GPA:', error);
    throw error;
  }
};
