// pages/404.tsx

import { Box, Text, Button, CloseButton } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as FourOFour } from 'src/assets/mobile/images/404.svg';
import theme from 'src/theme';

const NotFound = () => {
  const router = useNavigate();

  return (
    <>
      <Box
        alignContent="center"
        height="56px"
        minHeight="56px"
        marginBottom="24px"
        marginRight="24px"
        onClick={() => router('/')}
      >
        <CloseButton />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        textAlign="center"
        marginTop="-80px"
      >
        <Box marginBottom="56px">
          <FourOFour />
        </Box>
        <Text
          fontSize={theme.fonts.size.ultrabig}
          fontWeight="extrabold"
          color={theme.colors.mobile.texts.secondary}
          mb="4"
        >
          404
        </Text>
        <Text fontSize="lg" mb="8">
          صفحه مورد نظر یافت نشد
        </Text>
        <Button
          onClick={() => router('/')}
          backgroundColor={theme.colors.mobile.texts.secondary}
          color={theme.colors.white}
        >
          دسترسی به صفحه اصلی
        </Button>
      </Box>
    </>
  );
};

export default NotFound;
