import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from 'react';

// Define the shape of the context value
interface ModalContextProps {
  modalType: string | null;
  openModal: (type: string) => void;
  closeModal: () => void;
}

// Provide initial values and types
const ModalContext = createContext<ModalContextProps>({
  modalType: null,
  openModal: () => {
    console.warn('openModal function is not initialized');
  },
  closeModal: () => {
    console.warn('closeModal function is not initialized');
  },
});

export const useModal = () => useContext(ModalContext);

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [modalType, setModalType] = useState<string | null>(null);

  const openModal = useCallback((type: string) => {
    setModalType(type);
  }, []);

  const closeModal = useCallback(() => {
    setModalType(null);
  }, []);

  return (
    <ModalContext.Provider value={{ modalType, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};
