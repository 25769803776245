// theme.ts
import { extendTheme } from '@chakra-ui/react';

const styles = {
  global: {
    'html, body': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
      fontFamily: 'dana_regular',
    },
    '*': {
      boxSizing: 'inherit',
    },
  },
};

const breakpoints = {
  base: '0px',
  sm: '48em',
  md: '62em',
  lg: '80em',
  xl: '100em',
  '2xl': '110em',
};

export const theme = extendTheme({
  breakpoints,
  styles,
  components: {
    Button: {
      baseStyle: {
        fontFamily: 'inherit',
        fontWeight: 'normal',
        fontSize: '18px',
      },
    },
    Progress: {
      baseStyle: {
        filledTrack: {
          bgColor: '#d4f3e7',
        },
      },
    },
  },
  fonts: {
    family: {
      heading: `'dana_bold', sans-serif`,
      body: `'dana_regular', sans-serif`,
      medium: `'dana_medium', sans-serif`,
      bold: `'dana_bold', sans-serif`,
      ultra: `'dana_ultrabold', sans-serif`,
    },
    size: {
      ultrabig: '48px',
      semisemiultra: '40px',
      semiultra: '34px',
      ultra: '32px',
      big: '24px',
      semimedium: '20px',
      medium: '18px',
      semismall: '16px',
      small: '14px',
      lil: '12px',
      vlil: '10px',
    },
  },
  colors: {
    primary: '#2984F2',
    progress: {
      100: '#381DDB',
      200: '#381DDB',
      500: '#381DDB', // you need this
    },
    secondary: '#404040',
    third: '#56A2FF',
    hover: '#56A2FF',
    forminput: '#F0F4F8',
    pbg: '#F8F9FB',
    texts: {
      primary: '#2984F2',
      mobilecolor: '#1C2E5C',
      semiprimary: '#334E68',
      semisecondary: '#170F49',
      secondary: '#404040',
      third: '#56A2FF',
      textHover: '#56A2FF',
      white: '#ffff',
      dark: '#222222',
      semidark: '#333333',
      black: '#000000',
    },
    mobile: {
      pbg: '#F6F9FA',
      secondary: '#EBEBF0',
      gray: '#E5E5EA',
      red: 'rgba(255,59,48,0.7)',
      green: 'rgba(52,199,89,0.5)',
      mobileblue: '#3080E2',
      texts: {
        primary: '#20346A',
        secondary: '#3080E2',
      },
    },
  },
});

export default theme;
