import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Box,
  Flex,
  Button,
} from '@chakra-ui/react';
import { ReactComponent as CloseModalIcon } from '../../assets/mobile/icons/CloseModal.svg';

import theme from 'src/theme'; // Ensure the theme path is correct

interface ButtonProps {
  text: string;
  onClick: () => void;
  color?: string;
  bgColor?: string;
  hoverColor?: string;
  width?: string;
}

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  body: string;
  buttons: ButtonProps[];
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  title,
  body,
  buttons,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent
        borderTopRadius="lg"
        m="0 auto"
        marginRight="20px"
        bottom="0"
        position="fixed"
        w="100%"
        maxWidth="600px"
      >
        <Box position="absolute" top="24px" left="24px" onClick={onClose}>
          <CloseModalIcon />
        </Box>
        <ModalHeader
          textAlign="start"
          height="72px"
          borderBottom="solid 1px #EBEBF0"
        >
          {title}
        </ModalHeader>
        <ModalBody marginY="16px">{body}</ModalBody>
        <Flex
          width="100%"
          padding="16px"
          justifyContent="space-between"
          gap={buttons.length === 2 ? '10px' : '0'}
          borderTop="solid 1px #EBEBF0"
        >
          {buttons.map((button, index) => {
            // console.log(`Button ${index} render`, button);
            return (
              <Button
                key={index}
                onClick={() => {
                  // console.log(`Button ${index} clicked`);
                  button.onClick();
                }}
                width={button.width || '100%'}
                backgroundColor={
                  button.bgColor ||
                  (index === 0 ? theme.colors.secondary : '#EBEBF0')
                }
                color={
                  button.color ||
                  (index === 0
                    ? theme.colors.texts.white
                    : theme.colors.secondary)
                }
                _hover={{
                  backgroundColor:
                    button.hoverColor ||
                    (index === 0
                      ? theme.colors.secondary
                      : theme.colors.secondary),
                  color: theme.colors.texts.white,
                }}
                borderRadius="8px"
                h="48px"
              >
                {button.text}
              </Button>
            );
          })}
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
