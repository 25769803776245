// App.tsx

import React, { useContext, useEffect, useState } from 'react';
import { ChakraProvider, Box, Image } from '@chakra-ui/react';
import Header from '../containers/header/Header';
import ImageContent from '../containers/middlelanding/ImageContent';
import Devider from '../assets/images/Devider.svg';
import AboutUsContainer from '../containers/middlelanding/AboutUsContainer';
import Footer from '../containers/Footer';
import { AuthContext } from '../context/AuthContext';
import Loader from 'src/components/Loader';

const Home: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (authContext) {
      setLoading(false);
    }
  }, [authContext]);

  if (loading) {
    return (
      <Box textAlign="center" py={10}>
        <Loader color1="#56A2FF" color2="#404040" size={100} />
      </Box>
    );
  }
  return (
    <ChakraProvider>
      <Box textAlign="center" justifyContent="center" alignItems="center">
        <ImageContent />
        <Image src={Devider} w="100%" maxW="1170px" mx="auto" mb="80px" />
        <AboutUsContainer />
      </Box>
    </ChakraProvider>
  );
};

export default Home;
