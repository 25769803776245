// src/context/PlanContext.tsx

import { createContext, useContext, useState, ReactNode } from 'react';
import { PlanContextType, PlanType } from 'src/@types/PlanContextType';

const defaultContextValue: PlanContextType = {
  selectedPlan: null,
  setSelectedPlan: (plan: PlanType | null) => void plan,
  planShowed: false,
  setPlanShowed: (showed: boolean) => void showed,
};

const PlanContext = createContext<PlanContextType>(defaultContextValue);

export const usePlan = () => useContext(PlanContext);

export const PlanProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedPlan, setSelectedPlan] = useState<PlanType | null>(null);
  const [planShowed, setPlanShowed] = useState<boolean>(false);

  return (
    <PlanContext.Provider
      value={{ selectedPlan, setSelectedPlan, planShowed, setPlanShowed }}
    >
      {children}
    </PlanContext.Provider>
  );
};
