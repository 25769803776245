import React from 'react';
import HeaderContainer from './HeaderContainer';
import HeaderNav from './HeaderNav';
import HeaderContent from './HeaderContent';

const Header: React.FC = () => {
  return (
    <HeaderContainer>
      <HeaderNav />
      <HeaderContent />
    </HeaderContainer>
  );
};

export default Header;
