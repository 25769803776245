import React, { useEffect, useState, useContext } from 'react';
import { Box, Text, Link as ChakraLink } from '@chakra-ui/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import theme from 'src/theme';
import { AuthContext } from 'src/context/AuthContext';

interface NavMenuProps {
  title: string;
  TextColor: string;
}

const NavMenu: React.FC<NavMenuProps> = ({ title, TextColor }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const { isAuthenticated } = authContext || {};

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previousPath, setPreviousPath] = useState<string | null>(null);

  // Define paths for menu items
  const paths: { [key: string]: string } = {
    خانه: '/',
    وبلاگ: 'https://blog.applytory.com', // External blog URL
    'سوالات متداول': '/faq',
    'درباره ما': '/aboutus',
    'تماس با ما': '/contactus',
    'انواع اشتراک': '/purchase-plans',
  };

  // Conditionally add the "Dashboard" link if user is authenticated
  if (isAuthenticated) {
    paths['جستجوهای اخیر'] = '/dashboard';
  }

  const currentPath = paths[title];

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setPreviousPath(location.pathname); // Save the current path before opening the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate(previousPath ?? '/'); // Navigate to previous path or default
  };

  useEffect(() => {
    if (location.pathname !== previousPath) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, previousPath]);

  const handleExternalLink = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <>
      {!isAuthenticated && title === 'جستجوهای اخیر' ? (
        <></>
      ) : currentPath === 'https://blog.applytory.com' ? (
        <Text
          fontFamily={theme.fonts.family.body}
          fontSize={theme.fonts.size.medium}
          color={TextColor}
          cursor="pointer"
          onClick={() => handleExternalLink(currentPath)} // Use window.open for blog link
          _hover={{
            textDecoration: 'none',
            color: theme.colors.texts.textHover,
          }}
        >
          {title}
        </Text>
      ) : (
        <ChakraLink
          as={Link}
          to={currentPath}
          style={{ textDecoration: 'none' }}
        >
          <Text
            fontFamily={theme.fonts.family.body}
            fontSize={theme.fonts.size.medium}
            color={
              location.pathname === currentPath
                ? theme.colors.texts.textHover
                : TextColor
            }
            _hover={{
              textDecoration: 'none',
              color: theme.colors.texts.textHover,
            }}
          >
            {title}
          </Text>
        </ChakraLink>
      )}
    </>
  );
};

export default NavMenu;
